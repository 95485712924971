import { useEffect } from 'react';
import 'chartjs-adapter-date-fns';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export const useUpdateChart = (chartInstance: any,
  data: {
    labels?: Array<string | number>,
    datasets: Array<{
      label?: string | number,
      backgroundColor: Array<string> | string,
      borderColor?: Array<string> | string,
      borderWidth?: number,
      lineTension?: number,
      data: Array<{ x: number | Date, y: number }> | Array<number>
      stack?: string
      yAxisID?: string
      unit?: string
    }>

  },
  y1AxisTitle?: string,
  yAxisTitle?: string) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    //update in case of datasets change
    if (chartInstance) {
      const existingDatasetsLabels = data.datasets.map((dataset: any) => dataset.label);
      const newDatasets = chartInstance.data.datasets.filter((dataset: any) => existingDatasetsLabels.includes(dataset.label))
      chartInstance.data.datasets = newDatasets;

      if (chartInstance.data.datasets) {
        if (chartInstance.data && chartInstance.data.datasets && chartInstance.data !== chartInstance.data.datasets) {
          chartInstance.data.labels = data.labels
          //replace datasets data one by one
          data.datasets.forEach((dataset, i) => {
            const sameParam = chartInstance.data.datasets.find((ds: any) => ds.label === dataset.label);
            if (sameParam) {
              sameParam.data = dataset.data
              sameParam.label = dataset.label
              sameParam.backgroundColor = dataset.backgroundColor
              sameParam.borderColor = dataset.borderColor
              sameParam.lineTension = dataset.lineTension
              sameParam.stack = dataset.stack
              sameParam.yAxisID = dataset.yAxisID;
              sameParam.unit = dataset.unit;
            }
            else {
              chartInstance.data.datasets.push({
                data: dataset.data,
                label: dataset.label,
                backgroundColor: dataset.backgroundColor,
                borderColor: dataset.borderColor,
                lineTension: dataset.lineTension,
                stack: dataset.stack,
                yAxisID: dataset.yAxisID,
                unit: dataset.unit
              })
            }
          })
        }
      }
      
        chartInstance.options.scales.y.title.text = yAxisTitle;
        chartInstance.options.scales.y1.title.text = y1AxisTitle;
        chartInstance.options.scales.y1.display = data.datasets.some(dataset => dataset.yAxisID) ? true : false;
      
      if (data.labels) {
        data.labels.forEach((label, i) => {
          chartInstance.data.labels[i] = label
        })
      }
      if (matches) {
        chartInstance.options.aspectRatio = 2.5
      }
      else {
        chartInstance.options.aspectRatio = 0.8
      }
      chartInstance.update()
    }
  }, [chartInstance, data, matches, yAxisTitle, y1AxisTitle])
}