import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useState, Fragment } from "react";
import { HotelCurrent } from "./HotelCurrent.component";
import { Hotel1MinuteAvg } from "./Hotel1MinuteAvg.component";
import { Hotel10MinuteAvg } from "./Hotel10MinuteAvg.component";
import { PowerType } from "../../../services/power";

export const HotelPower = () => {
    const [tabValue, setTabValue] = useState<PowerType>('current');

    const getComponent = (viewType: PowerType): React.ReactNode => {
        switch (viewType) {
            case 'current': {
                return <HotelCurrent />
            }
            case '1minAggregated': {
                return <Hotel1MinuteAvg />
            }
            case '10minAggregated': {
                return <Hotel10MinuteAvg />
            }
            default: {
                return null;
            }
        }
    }

    return (
        <Fragment>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} variant="scrollable" scrollButtons="auto">
                    <Tab label="Aktualne dane" onClick={() => setTabValue('current')} value='current' />
                    <Tab label="Średnie 1-min" onClick={() => setTabValue('1minAggregated')} value='1minAggregated' />
                    <Tab label="Średnie 10-min" onClick={() => setTabValue('10minAggregated')} value='10minAggregated' />
                </Tabs>
            </Box>
            {getComponent(tabValue)}
        </Fragment>
    )
}