import { useState, Fragment } from 'react';
import Grid from '@mui/material/Grid';
import format from 'date-fns/format';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import LocalGasStationOutlinedIcon from '@mui/icons-material/LocalGasStationOutlined';
import DeviceThermostatOutlinedIcon from '@mui/icons-material/DeviceThermostatOutlined';
import OilBarrelOutlinedIcon from '@mui/icons-material/OilBarrelOutlined';
import Battery4BarOutlinedIcon from '@mui/icons-material/Battery4BarOutlined';
import BatteryCharging60OutlinedIcon from '@mui/icons-material/BatteryCharging60Outlined';
import { Accordion } from './Accordion.component';
import { AccordionSummary } from './AccordionSummary.component';
import { AccordionDetails } from './AccordionDetails.component';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useGetCurrentQuery } from '../services/current';

const unitStyle = {
    fontSize: 20
}

const valueStyle = {
    fontSize: 42
}

export const GeneratorPage = () => {
    const { data } = useGetCurrentQuery(undefined, { pollingInterval: 6000, refetchOnMountOrArgChange: true });
    const [expanded, setExpanded] = useState<string | false>('generator2');

    const decideFuelAlertLevel = (fuel: number) => {
        if (fuel > 75) {
            return 'success'
        }
        if (fuel > 40) {
            return 'warning'
        }
        if (fuel > 20) {
            return 'error'
        }
        return 'success'
    }

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    return (
        <Fragment>
            <Accordion expanded={expanded === 'generator2'} onChange={handleChange('generator2')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={{ flexGrow: 1 }}>Generator 2</Typography>
                    <Typography>Stan na: {data?.time_value ? format(new Date(data.time_value), 'dd/MM/yyyy HH:mm:ss') : '-'}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} lg={4}>
                            <Alert severity={data?.generatorN?.fuel_level !== undefined ? decideFuelAlertLevel(data.generatorN.fuel_level) : 'success'} variant="outlined" sx={valueStyle}
                                icon={<LocalGasStationOutlinedIcon fontSize="inherit" />}>
                                <AlertTitle>Poziom paliwa</AlertTitle>
                                {data?.generatorN.fuel_level || 0} <span style={unitStyle}>%</span>
                            </Alert>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <Alert severity="success" variant="outlined" sx={valueStyle}
                                icon={<DeviceThermostatOutlinedIcon fontSize="inherit" />}>
                                <AlertTitle>Temperatura silnika</AlertTitle>
                                {data?.generatorN.engine_temp || 0} <span style={unitStyle}>°C</span>
                            </Alert>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <Alert severity="success" variant="outlined" sx={valueStyle}
                                icon={<OilBarrelOutlinedIcon fontSize="inherit" />}>
                                <AlertTitle>Ciśnienie oleju</AlertTitle>
                                {data?.generatorN.oil_pressure || 0} <span style={unitStyle}>bar</span>
                            </Alert>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <Alert severity="success" variant="outlined" sx={valueStyle}
                                icon={<Battery4BarOutlinedIcon fontSize="inherit" />}>
                                <AlertTitle>Napięcie akumulatora</AlertTitle>
                                {data?.generatorN.battery_voltage || 0} <span style={unitStyle}>V</span>
                            </Alert>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <Alert severity="success" variant="outlined" sx={valueStyle}
                                icon={<BatteryCharging60OutlinedIcon fontSize="inherit" />}>
                                <AlertTitle>Napięcie ładowania</AlertTitle>
                                {data?.generatorN.charge_voltage || 0} <span style={unitStyle}>V</span>
                            </Alert>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Fragment>
    )
}