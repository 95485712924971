import { add, endOfMonth, format, startOfMonth } from 'date-fns';
import { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { createEnergyChartDatasetStructure } from '../../../utilities/CreateChartDataStructure.utility';
import { BarChart } from '../../BarChart.component';
import { IInverterEnergy } from '../types';
import { PVEnergy, useGetEnergyQuery } from '../../../services/energy';
import { RootState } from '../../../store';

const initialState = {
    labels: [],
    datasets: []
}

export const SegmentedEnergy = () => {
    const selectedDate = useSelector((state: RootState) => state.toolbar.time);
    const [daysOfMonthSegmentedChartData, setDaysOfMonthSegmentedChartData] = useState<IInverterEnergy>(initialState);

    const { data } = useGetEnergyQuery({
        type: 'pv',
        timeFrom: format(startOfMonth(selectedDate), 'yyyy-MM-dd'),
        timeTo: format(add(endOfMonth(selectedDate), {
            days: 1
        }), 'yyyy-MM-dd')
    }, { refetchOnMountOrArgChange: true })

    useEffect(() => {
        if (data) {
            const structured = createEnergyChartDatasetStructure(data as PVEnergy[], selectedDate);

            setDaysOfMonthSegmentedChartData({
                labels: structured.labels,
                datasets: structured.segmentedEnergy
            })
        }
    }, [data, selectedDate])

    return (
        <Fragment>
            <BarChart
                stacked
                data={{
                    labels: daysOfMonthSegmentedChartData.labels,
                    datasets: daysOfMonthSegmentedChartData.datasets
                }}
                chartTitle="Energia w poszczególnych dniach"
                yAxisUnit="kWh"
            />
        </Fragment>
    )
}