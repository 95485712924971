import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useEffect, Fragment, useState } from 'react';
import Box from '@mui/material/Box';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSetDatepicker } from '../../hooks/useSetDatepicker.hook';

export const EnergyPage = () => {
  const [tabValue, setTabValue] = useState('fotowoltaika');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.indexOf('zaklady') !== -1) {
      setTabValue('zaklady')
    } else if (location.pathname.indexOf('hotele') !== -1) {
      setTabValue('hotele')
    }
    else if (location.pathname.indexOf('fotowoltaika') !== -1) {
      setTabValue('fotowoltaika')
    }
  }, [location, setTabValue])

  useSetDatepicker('day');

  return (
    <Fragment>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} variant="scrollable" scrollButtons="auto">
          <Tab label="Zakłady" onClick={() => navigate('zaklady')} value='zaklady' />
          <Tab label="Hotele" onClick={() => navigate('hotele')} value='hotele' />
          <Tab label="Fotowoltaika" onClick={() => navigate('fotowoltaika')} value='fotowoltaika' />
        </Tabs>
      </Box>
      <Outlet />
    </Fragment>
  )
}