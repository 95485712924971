import { IPredictions, useGetPredictionsQuery } from "../services/tensorflow";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from "@mui/material/Box";
import { endOfDay, startOfDay } from "date-fns";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { useSetDatepicker } from "../hooks/useSetDatepicker.hook";
import { format } from "date-fns";
import { Fragment, useState } from "react";
import { FormControlLabel, Switch, Alert, AlertTitle, Grid, CircularProgress, TextField, Typography, Divider, MenuItem } from "@mui/material";
import Stack from '@mui/material/Stack';
import { pl } from 'date-fns/locale';

const columns = ['co2', 'co2_zadana', 't_podloza_1', 't_podloza_2', 't_podloza_3', 't_podloza_4',
    't_podloza_5', 't_podloza_6', 't_podloza_zadana', 't_chlodnicy', 't_nagrzewnicy_1', 'wy_wentylacja_glowna', 'wy_doplyw_powietrza_zew',
    'wy_dodatkowego_wymiennika_ciepla', 'wy_chlodzenie', 'wy_osuszanie', 'wy_grzanie', 'wy_nawilzanie', 'wy_nagrzewnica_wstepna', 'wy_para',
    'faza', 't_nagrzewnicy_wstepnej', 'rh_powietrza', 'rh_powietrza_zadana', 't_powietrza', 't_powietrza_zadana']

const halls = ['31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45',
    '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60']

const round = (number: number, precision: number) => {
    return parseFloat(number.toFixed(precision));
}

export const Predictions = () => {
    const { time } = useSelector((state: RootState) => state.toolbar);
    const [showErrors, setShowErrors] = useState<boolean>(false);
    const [highlightThresholdPercentage, setHighlightThresholdPercentage] = useState<string>('10');
    const [selectedHall, setSelectedHall] = useState<string>(halls[0]);

    const { data: predictions, isFetching } = useGetPredictionsQuery({
        id: selectedHall,
        timeFrom: new Date(startOfDay(time)).valueOf(),
        timeTo: new Date(endOfDay(time)).valueOf()
    }, { refetchOnMountOrArgChange: true });

    useSetDatepicker('10min');

    return (
        <Fragment>
            <Alert severity="info" style={{ padding: 20, marginBottom: 10 }}>
                <AlertTitle>Wykrywanie anomalii TensorFlow</AlertTitle>
                <Box mt={5}>
                    <Stack spacing={2}>
                        <Typography>Data: {format(time, 'EEEE, dd MMMM yyyy', { locale: pl })}</Typography>
                        <Divider orientation="horizontal" flexItem />

                        <TextField
                            select
                            label="Wybierz halę"
                            value={selectedHall}
                            onChange={event => setSelectedHall(event.target.value)}
                        >
                            {halls.map((hall) => (
                                <MenuItem key={hall} value={hall}>
                                    {hall}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            label="Procent błędu"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={highlightThresholdPercentage}
                            onChange={(event => setHighlightThresholdPercentage(event.target.value))}
                            variant="outlined"
                            helperText="Odchylenia powyżej tej wartości będą oznaczane jako anomalie"
                        />

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={showErrors}
                                    onChange={(event => setShowErrors(event.target.checked))}
                                />
                            }
                            label="Pokaż błędy"
                        />
                    </Stack>
                </Box>
            </Alert>

            {
                isFetching ? <Box pt={5} sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box> :
                    <Box>

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="label-cell">Godzina</TableCell>
                                        {columns.map((column, index) => {
                                            return <TableCell className="label-cell" key={index}>{column}</TableCell>
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {predictions?.map((prediction: IPredictions, index: number) => {
                                        return <TableRow key={index}>
                                            <TableCell align="right" className="label-cell">{format(new Date(prediction.timestamp), 'HH:mm')}</TableCell>
                                            {Object.values(prediction.params).map((value, i) => {
                                                return <TableCell key={i}
                                                    className={Math.abs(value.error / value.prediction * 100) > parseInt(highlightThresholdPercentage) ? 'warning-cell' : ''}
                                                    align="right">{showErrors ? round(value.error, 4) : round(value.prediction, 4)}</TableCell>
                                            })}</TableRow>
                                    })}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
            }
            /</Fragment>
    )
}