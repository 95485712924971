import { Fragment } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { format } from 'date-fns';
import { useGetLogsQuery } from '../services/logs';

export const LogsPage = () => {
    const { data: errors } = useGetLogsQuery(undefined, { refetchOnMountOrArgChange: true, pollingInterval: 60000 });

    return (
        <Fragment>
            <Box sx={{ p: { xs: 0, sm: 1, md: 2 } }}>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Data</TableCell>
                                <TableCell>Logi</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {errors?.map((row) => (
                                <TableRow
                                    key={row.time_value}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {format(new Date(row.time_value), 'dd/MM/yyyy HH:mm:ss')}
                                    </TableCell>
                                    <TableCell><ul>{row.error_text.split(';').map((err) => <li key={err}>{err}</li>)}</ul></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Fragment>
    )
}