import { useEffect, useState, Fragment } from 'react';
import Box from '@mui/material/Box';
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Settings } from './Settings.component';

export const PowerPage = () => {
  const [tabValue, setTabValue] = useState('zaklady');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.indexOf('zaklady') !== -1) {
      setTabValue('zaklady')
    } else if (location.pathname.indexOf('hotele') !== -1) {
      setTabValue('hotele')
    }
  }, [location, setTabValue])

  return (
    <Fragment>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} variant="scrollable" scrollButtons="auto">
          <Tab label="Zakłady" onClick={() => navigate('zaklady')} value='zaklady' />
          <Tab label="Hotele" onClick={() => navigate('hotele')} value='hotele' />
        </Tabs>
      </Box>
      <Outlet />
      <Settings/>
    </Fragment >
  )
}