import { configureStore } from '@reduxjs/toolkit'
import { currentApi } from './services/current'
import { setupListeners } from '@reduxjs/toolkit/query'
import alertDialogReducer from './features/AlertDialog/alertDialogSlice'
import toolbarReducer from './features/toolbar/toolbarSlice'
import { logsApi } from './services/logs'
import { powerApi } from './services/power'
import { energyApi } from './services/energy'
import { tensorflowApi } from './services/tensorflow'
import { customChartsApi } from './services/customCharts'

export const store = configureStore({
  reducer: {
    [currentApi.reducerPath]: currentApi.reducer,
    [logsApi.reducerPath]: logsApi.reducer,
    [powerApi.reducerPath]: powerApi.reducer,
    [energyApi.reducerPath]: energyApi.reducer,
    [tensorflowApi.reducerPath]: tensorflowApi.reducer,
    [customChartsApi.reducerPath]: customChartsApi.reducer,
    alertDialog: alertDialogReducer,
    toolbar: toolbarReducer
  },
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), currentApi.middleware, logsApi.middleware,
  powerApi.middleware, energyApi.middleware, tensorflowApi.middleware,
  customChartsApi.middleware]
})

setupListeners(store.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch