// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export interface Power {
    time_value: string
    inst_inverter1_power: number
    inst_inverter2_power: number
    inst_inverter3_power: number
    inst_inverter4_power: number
    inst_inverter5_power: number
    inst_inverter6_power: number
    inst_inverter7_power: number
    inst_inverter8_power: number
    inst_inverter9_power: number
    inst_n_grid_generator_power: number
    inst_m_grid_generator_power: number
    inst_inverter10_power: number
    inst_inverter11_power: number
    inst_inverter12_power: number
    inst_inverter13_power: number
    inst_inverter14_power: number
    inst_inverter15_power: number
    inst_inverter16_power: number
    inst_m_inverter1_power: number
    inst_hotel2_power: number
    inst_hotel1_power: number
}

export interface HotelPower {
    inst_hotel1_power: number
    inst_hotel2_power: number
    inst_hotel1_inverter1_power: number
    inst_hotel1_inverter2_power: number
    inst_hotel2_inverter1_power: number
    inst_hotel2_inverter2_power: number
    time_value: string
}

export interface PowerLoss {
    power_sum_n: number
    power_sum_m: number
}

export type PowerType = 'current' | '1minAggregated' | '10minAggregated' | 'limited'

// Define a service using a base URL and expected endpoints
export const powerApi = createApi({
    reducerPath: 'powerApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/' }),
    endpoints: (builder) => ({
        getPower: builder.query<Power[] | PowerLoss, { type: PowerType, timeFrom: number, timeTo: number }>({
            query: (data) => `power/${data.type}/${data.timeFrom}/${data.timeTo}`
        }),
        getHotelPower: builder.query<HotelPower[], { type: PowerType, timeFrom: number, timeTo: number }>({
            query: (data) => `hotelPower/${data.type}/${data.timeFrom}/${data.timeTo}`
        }),
    }),
})

export const { useGetPowerQuery, useGetHotelPowerQuery } = powerApi