import Chart from 'chart.js/auto';
import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { useUpdateChart } from '../hooks/useUpdateChart.hook';

interface BarChartProps {
  data: {
    labels: Array<string | number>,
    datasets: Array<{
      label: string | number,
      data: Array<number>,
      backgroundColor: Array<string>,
      borderColor: Array<string>,
      borderWidth: number
      stack?: string
    }>
  },
  chartTitle?: string,
  yAxisUnit?: string,
  yAxisTitle?: string,
  xAxisTitle?: string,
  stacked?: boolean
}

export const BarChart: React.FC<BarChartProps> = ({ data,
  chartTitle,
  yAxisUnit,
  yAxisTitle,
  xAxisTitle,
  stacked }) => {
  const chartContainer = useRef() as React.MutableRefObject<HTMLCanvasElement>;
  const [chartInstance, setChartInstance] = useState<Chart | null>(null);
  const theme = useTheme();

  useUpdateChart(chartInstance, data)

  const chartConfig = useMemo(() => {
    return {
      type: 'bar',
      options: {
        responsive: true,
        aspectRatio: window.innerWidth <= 768 ? 0.8 : 2.5,
        interaction: {
          intersect: false,
          mode: 'index',
        },
        plugins: {
          legend: {
            display: stacked ? true : false,
            labels: {
              color: theme.palette.text.primary,
              font: {
                size: window.innerWidth <= 768 ? 12 : 14,
              },
              boxWidth: window.innerWidth <= 768 ? 20 : 40
            }
          },
          tooltip: {
            callbacks: {
              label: function (context: any) {
                let label = context.dataset.label || '';
                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += context.parsed.y
                }
                if (label && yAxisUnit) {
                  label += ` ${yAxisUnit}`;
                }
                return label;
              }
            }
          },
          title: {
            display: chartTitle ? true : false,
            text: chartTitle ? chartTitle : '',
            fontColor: theme.palette.text.primary,
            color: theme.palette.text.primary
          },

        },
        legend: {
          labels: {
            fontColor: theme.palette.text.primary,
          }
        },

        scales: {
          x: {
            stacked: stacked ? true : false,
            title: {
              display: xAxisTitle ? true : false,
              text: xAxisTitle ? xAxisTitle : '',
              font: {
                size: 18,
                weight: 400
              },
            },
            ticks: {
              color: theme.palette.text.primary
            }
          },
          y: {
            stacked: stacked ? true : false,
            title: {
              text: yAxisTitle ? yAxisTitle : '',
              display: yAxisTitle ? true : false,
              color: theme.palette.text.primary,
              font: {
                size: 18,
                weight: 400
              }
            },
            ticks: {
              color: theme.palette.text.primary
            }
          },
          y1: {
            title: {

            }
          }
        },
      },
      data
    }
  }, [data, chartTitle, theme.palette.text.primary, yAxisTitle, yAxisUnit, xAxisTitle, stacked]);

  useEffect(() => {
    setChartInstance(new Chart(chartContainer.current, chartConfig as any))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      <canvas ref={chartContainer} />
    </React.Fragment>
  )
}