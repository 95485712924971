import Grid from '@mui/material/Grid';
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { subscribeUser, unsubscribe, isSubscribed } from '../services/subscription';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import SendIcon from '@mui/icons-material/Send';
import { Alert, AlertTitle, IconButton, TextField, useTheme } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';
import { ColorModeContext } from '../App';
import LightModeIcon from '@mui/icons-material/LightMode';
import ModeNightIcon from '@mui/icons-material/ModeNight';
import ContrastIcon from '@mui/icons-material/Contrast';

export const SettingsPage = () => {
    const colorMode = useContext(ColorModeContext);
    const [userIsSubscribed, setUserIsSubscribed] = useState<null | boolean>(null);
    const [loading, setLoading] = useState(false);
    const [buttonLabel, setButtonLabel] = useState('Anuluj subskrypcję');
    const theme = useTheme();

    const checkSubscription = useCallback(() => {
        isSubscribed().then(subscribed => {
            setLoading(false);
            if (subscribed) {
                setUserIsSubscribed(true)
            } else {
                setUserIsSubscribed(false)
            }
        })
    }, [setUserIsSubscribed, setLoading])

    useEffect(() => {
        if (userIsSubscribed) {
            setButtonLabel('Anuluj subskrypcję')
        } else {
            setButtonLabel('Subskrybuj')
        }
    }, [userIsSubscribed])

    useEffect(() => {
        checkSubscription()
    }, [checkSubscription])

    const manageSubscription = async () => {
        setLoading(true);

        if (userIsSubscribed) {
            await unsubscribe()
        } else {
            await subscribeUser();
        }
        checkSubscription()
    }

    return (
        <Fragment>
            <Box p={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Alert severity="success" variant="outlined" sx={{ fontSize: 42 }}
                            icon={<DataThresholdingIcon fontSize="inherit" />}>
                            <AlertTitle>Progi powiadomień</AlertTitle>
                            <TextField
                                disabled
                                label="Moc N"
                                defaultValue="430 kW"
                                variant="standard"
                                fullWidth
                            />
                            <TextField
                                disabled
                                label="Moc M"
                                defaultValue="450 kW"
                                variant="standard"
                                fullWidth
                            />
                        </Alert>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Alert severity="success" variant="outlined" sx={{ fontSize: 42 }}
                            icon={<ContrastIcon fontSize="inherit" />}>
                            <AlertTitle>Motyw aplikacji</AlertTitle>
                            <IconButton size="large" color="inherit" onClick={colorMode.toggleColorMode} >
                                {theme.palette.mode === 'dark' ? <LightModeIcon /> : <ModeNightIcon />}
                            </IconButton>
                        </Alert>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Alert severity="success" variant="outlined" sx={{ fontSize: 42 }}
                            icon={<NotificationsNoneIcon fontSize="inherit" />}>
                            <AlertTitle>Powiadomienia Push</AlertTitle>
                            <LoadingButton
                                onClick={manageSubscription}
                                endIcon={<SendIcon />}
                                loading={loading}
                                loadingPosition="end"
                                variant="outlined"
                                color={userIsSubscribed ? 'error' : 'success'}
                            >
                                {buttonLabel}
                            </LoadingButton>
                        </Alert>
                    </Grid>

                </Grid>
            </Box>
        </Fragment>
    )
}