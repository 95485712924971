import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface ITimeSkip {
    unit: 'minutes' | 'hours' | 'days' | 'months',
    value: number
}

export interface ToolbarState {
    autoRefresh: boolean,
    time: number,
    timePicker: {
        type: 'date' | 'datetime',
        views: Array<string>,
        skipValue: ITimeSkip
    }
}

const initialState: ToolbarState = {
    autoRefresh: true,
    time: new Date().valueOf(),
    timePicker: {
        type: 'datetime',
        views: ['day', 'hours', 'minutes'],
        skipValue: {
            unit: 'minutes',
            value: 4
        }
    }

}

export const toolbarSlice = createSlice({
    name: 'toolbar',
    initialState,
    reducers: {
        setAutoRefresh: (state, action: PayloadAction<boolean>) => {
            state.autoRefresh = action.payload;
        },
        setDate: (state, action: PayloadAction<any>) => {
            state.time = action.payload;
        },
        setTimePicker: (state, action: PayloadAction<{
            type: 'date' | 'datetime',
            views: Array<string>,
            skipValue: ITimeSkip
        }>) => {
            state.timePicker = action.payload;
        }
    },
})

export const { setAutoRefresh, setDate, setTimePicker } = toolbarSlice.actions

export default toolbarSlice.reducer