import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Fragment, useState } from 'react';
import Box from '@mui/material/Box';
import { EnergyObject } from '../../../services/energy';
import { Hotel1Energy } from './Hotel1Energy.component';

export const HotelEnergy = () => {
  const [tabValue, setTabValue] = useState<EnergyObject>('hotel1');

  const getComponent = (viewType: EnergyObject): React.ReactNode => {
    switch (viewType) {
      case 'hotel1': {
        return <Hotel1Energy />
      }
      default: {
        return null;
      }
    }
  }

  return (
    <Fragment>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} variant="scrollable" scrollButtons="auto">
          <Tab label="Hotel 1" onClick={() => setTabValue('hotel1')} value='hotel1' />
          <Tab disabled label="Hotel 2" onClick={() => setTabValue('hotel2')} value='hotel2' />
        </Tabs>
      </Box>
      {getComponent(tabValue)}
    </Fragment>
  )
}