const convertedVapidKey = urlBase64ToUint8Array("BO3Y2SsU0GOlMokm_uIet-To58Ovgff59jRUky4bav0s1Og-YQG3_xJNjTdaIIv_vWcXo_-ny28wTm15U55Vv9U")

function urlBase64ToUint8Array(base64String: string) {
  const padding = "=".repeat((4 - base64String.length % 4) % 4)
  // eslint-disable-next-line
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

export const getSubscription = async () => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.ready;
      const subscription = await registration.pushManager.getSubscription();

      return {
        registration,
        subscription
      };
    } catch (err) {
      console.log(err)
    }
  }
}

export const subscribeUser = async () => {
  const existingSubscription = await getSubscription();

  if (existingSubscription?.subscription === null) {
    console.log('No subscription detected, make a request.')
    try {
      const newSubscription = await existingSubscription.registration.pushManager.subscribe({
        applicationServerKey: convertedVapidKey,
        userVisibleOnly: true,
      })

      console.log('New subscription added.', newSubscription)
      await subscribe(newSubscription)
    } catch (err) {
      if (Notification.permission !== 'granted') {
        console.log('Permission was not granted.')
      } else {
        console.error('An error ocurred during the subscription process.', err)
      }
    }

  } else {
    console.log('Existing subscription detected.')
    await subscribe(existingSubscription?.subscription)
  }
}


export const subscribe = async (subscription: any) => {
  const response = await fetch(`/notifications/subscribe`, {
    method: 'POST',
    body: JSON.stringify({
      subscription: subscription
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })

  const data = await response.json();
  return data
}

export const unsubscribe = async () => {
  const subData = await getSubscription();

  const response = await fetch(`/notifications/unsubscribe`, {
    method: 'POST',
    body: JSON.stringify({
      subscription: subData?.subscription
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })

  const data = await response.json();
  await subData?.subscription?.unsubscribe();
  return data;
}

export const isSubscribed = async () => {
  const subData = await getSubscription()

  if (!subData?.subscription) {
    return false
  }

  const response = await fetch(`/notifications/isSubscribed`, {
    method: 'POST',
    body: JSON.stringify({
      subscription: subData?.subscription
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })

  const data = await response.json();
  return data;
}