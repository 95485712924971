import { setPrecision } from "./SetPrecision.utility";
import { format, startOfDay, endOfMonth, startOfMonth } from 'date-fns';
import { colors } from '../utilities/Colors.utility';
import { HotelPower, Power } from "../services/power";
import { HotelEnergy, PVEnergy, PlantsEnergy } from "../services/energy";

interface ChartAxis {
  x: number
  y: number
}

export const createPowerChartDatasetStructure = (apiResponse: Array<Power>) => {
  const data = [...apiResponse];
  data.sort((a: Power, b: Power) => new Date(a.time_value).valueOf() - new Date(b.time_value).valueOf())

  const inst_n_grid_generator_power: ChartAxis[] = data.map((item: Power) => {
    return {
      x: new Date(item.time_value).valueOf(),
      y: setPrecision(item.inst_n_grid_generator_power, 2)
    }
  })
  const inst_m_grid_generator_power: ChartAxis[] = data.map((item: Power) => {
    return {
      x: new Date(item.time_value).valueOf(),
      y: setPrecision(item.inst_m_grid_generator_power, 2)
    }
  })
  const totalInvertersPowerPlant2: ChartAxis[] = data.map((item: Power) => {
    return {
      x: new Date(item.time_value).valueOf(),
      y: setPrecision(item.inst_inverter1_power +
        item.inst_inverter2_power +
        item.inst_inverter3_power +
        item.inst_inverter4_power +
        item.inst_inverter5_power +
        item.inst_inverter6_power +
        item.inst_inverter11_power +
        item.inst_inverter15_power +
        item.inst_inverter16_power, 2)
    }
  })
  const totalInverterEPM2Limited: ChartAxis[] = data.map((item: Power) => {
    return {
      x: new Date(item.time_value).valueOf(),
      y: setPrecision(item.inst_inverter1_power +
        item.inst_inverter2_power +
        item.inst_inverter3_power +
        item.inst_inverter4_power +
        item.inst_inverter5_power +
        item.inst_inverter11_power, 2)
    }
  })
  const totalInvertersPowerPlant1: ChartAxis[] = data.map((item: Power) => {
    return {
      x: new Date(item.time_value).valueOf(),
      y: setPrecision(item.inst_inverter7_power +
        item.inst_inverter8_power +
        item.inst_inverter9_power +
        item.inst_inverter10_power +
        item.inst_inverter12_power +
        item.inst_inverter13_power +
        item.inst_inverter14_power +
        item.inst_m_inverter1_power, 2)
    }
  })
  const unlimitedInverters: ChartAxis[] = data.map((item: Power) => {
    return {
      x: new Date(item.time_value).valueOf(),
      y: setPrecision(item.inst_inverter6_power, 2)
    }
  })
  const consumedPower2: ChartAxis[] = totalInvertersPowerPlant2.map((item: ChartAxis, index: number) => {
    return {
      x: new Date(item.x).valueOf(),
      y: setPrecision(item.y +
        data[index].inst_n_grid_generator_power, 2)
    }
  })

  const consumedPower1: ChartAxis[] = totalInvertersPowerPlant1.map((item: ChartAxis, index: number) => {
    return {
      x: new Date(item.x).valueOf(),
      y: setPrecision(item.y +
        data[index].inst_m_grid_generator_power -
        data[index].inst_hotel1_power -
        data[index].inst_hotel2_power, 2)
    }
  })

  // const nonLimitedPowerPowerPlant1 = apiResponse.map((item: {
  //   sum1: number
  //   sum2: number
  // }) => {
  //   const powerPlant1InvertersSum = item.sum1;
  //   const powerPlant2InvertersSum = item.sum2;


  //   return {
  //     x: new Date(item.time_value).valueOf(),
  //     y: setPrecision(nonLimitedPower, 2)
  //   }
  // })

  return {
    inst_n_grid_generator_power,
    inst_m_grid_generator_power,
    totalInvertersPowerPlant1,
    totalInvertersPowerPlant2,
    consumedPower2,
    consumedPower1,
    totalInverterEPM2Limited,
    unlimitedInverters
  }
}

export const mapHotelsPowerToChart = (apiResponse: Array<HotelPower>) => {
  const data = [...apiResponse];
  data.sort((a: HotelPower, b: HotelPower) => new Date(a.time_value).valueOf() - new Date(b.time_value).valueOf())
  const hotel1GridPower = data.map((item) => {
    return {
      x: new Date(item.time_value).valueOf(),
      y: setPrecision(item.inst_hotel1_power, 2)
    }
  })

  const hotel1PvPower = data.map((item) => {
    return {
      x: new Date(item.time_value).valueOf(),
      y: setPrecision(item.inst_hotel1_inverter1_power + item.inst_hotel1_inverter2_power, 2)
    }
  })

  const hotel1Power = data.map((item) => {
    return {
      x: new Date(item.time_value).valueOf(),
      y: setPrecision(item.inst_hotel1_power + (item.inst_hotel1_inverter1_power + item.inst_hotel1_inverter2_power), 2)
    }
  })

  const hotel2GridPower = data.map((item) => {
    return {
      x: new Date(item.time_value).valueOf(),
      y: setPrecision(item.inst_hotel2_power, 2)
    }
  })

  const hotel2PvPower = data.map((item) => {
    return {
      x: new Date(item.time_value).valueOf(),
      y: setPrecision(item.inst_hotel2_inverter1_power + item.inst_hotel2_inverter2_power, 2)
    }
  })

  const hotel2Power = data.map((item) => {
    return {
      x: new Date(item.time_value).valueOf(),
      y: setPrecision(item.inst_hotel2_power + (item.inst_hotel2_inverter1_power + item.inst_hotel2_inverter2_power), 2)
    }
  })

  return {
    hotel1GridPower,
    hotel1PvPower,
    hotel1Power,
    hotel2GridPower,
    hotel2PvPower,
    hotel2Power
  }
}

export const mapHotels1MinAvgPowerToChart = (apiResponse: Array<Power>) => {
  const data = [...apiResponse]

  data.sort((a: Power, b: Power) => new Date(a.time_value).valueOf() - new Date(b.time_value).valueOf())
  return apiResponse.map((item) => {
    return {
      x: new Date(item.time_value).valueOf(),
      y: setPrecision(item.inst_hotel1_power, 2)
    }
  })
}

export const mapHotels10MinAvgPowerToChart = (apiResponse: Array<Power>) => {
  const data = [...apiResponse]

  data.sort((a: Power, b: Power) => new Date(a.time_value).valueOf() - new Date(b.time_value).valueOf())
  return data.map((item) => {
    return {
      x: new Date(item.time_value).valueOf(),
      y: setPrecision(item.inst_hotel1_power, 2)
    }
  })
}

export const createEnergyPlantDatasetStructure = (energyData: PlantsEnergy[], selectedDate: number) => {
  let pointer = startOfMonth(selectedDate).valueOf()
  const labels = []
  while (pointer < endOfMonth(selectedDate).valueOf()) {
    labels.push({
      numericValue: startOfDay(pointer).valueOf(),
      chartLabel: format(startOfDay(pointer).valueOf(), 'yyyy-MM-dd')
    })
    pointer += 86400000
  }

  const energy: Omit<PlantsEnergy, 'time_value'>[] = labels.map((label: {
    numericValue: number,
    chartLabel: string
  }) => {

    const isPresent = energyData.findIndex((el: PlantsEnergy) => label.chartLabel === el.time_value)
    if (isPresent !== -1) {
      return {
        daily_m_grid_energy: energyData[isPresent].daily_m_grid_energy,
        daily_n_grid_energy: energyData[isPresent].daily_n_grid_energy,
        daily_hotel1_import_energy: energyData[isPresent].daily_hotel1_import_energy,
        daily_hotel1_export_energy: energyData[isPresent].daily_hotel1_export_energy,
        daily_hotel2_import_energy: energyData[isPresent].daily_hotel2_import_energy,
        daily_hotel2_export_energy: energyData[isPresent].daily_hotel2_export_energy,
        daily_plant_n_energy: energyData[isPresent].daily_plant_n_energy,
        daily_plant_m_energy: energyData[isPresent].daily_plant_m_energy
      }
    }
    else {
      return {
        daily_m_grid_energy: 0,
        daily_n_grid_energy: 0,
        daily_hotel1_import_energy: 0,
        daily_hotel1_export_energy: 0,
        daily_hotel2_import_energy: 0,
        daily_hotel2_export_energy: 0,
        daily_plant_n_energy: 0,
        daily_plant_m_energy: 0
      }
    }
  })

  const summarizedDatasets = [{
    label: 'Energia pobrana z sieci - M',
    backgroundColor: [colors.petrol],
    borderColor: [colors.petrol],
    borderWidth: 1,
    data: energy.map((el) => {
      return setPrecision(el.daily_m_grid_energy, 2)
    }),
    stack: 'Stack 0',
  },
  {
    label: 'Energia wyprodukowana PV - M',
    backgroundColor: [colors.lightOrange],
    borderColor: [colors.lightOrange],
    borderWidth: 1,
    data: energy.map((el) => {
      return setPrecision(el.daily_plant_m_energy, 2)
    }),
    stack: 'Stack 0',
  },
  {
    label: 'Energia pobrana hotele - M',
    backgroundColor: [colors.darkViolet],
    borderColor: [colors.darkViolet],
    borderWidth: 1,
    data: energy.map((el) => {
      return setPrecision(-el.daily_hotel1_import_energy + el.daily_hotel1_export_energy + el.daily_hotel2_export_energy - el.daily_hotel2_import_energy, 2)
    }),
    stack: 'Stack 0',
  },
  {
    label: 'Energia pobrana z sieci - N',
    backgroundColor: [colors.darkBlue],
    borderColor: [colors.darkBlue],
    borderWidth: 1,
    data: energy.map((el) => {
      return setPrecision(el.daily_n_grid_energy, 2)
    }),
    stack: 'Stack 1',
  },
  {
    label: 'Energia wyprodukowana PV - N',
    backgroundColor: [colors.darkOrange],
    borderColor: [colors.darkOrange],
    borderWidth: 1,
    data: energy.map((el) => {
      return setPrecision(el.daily_plant_n_energy, 5)
    }),
    stack: 'Stack 1',
  }
  ]

  const finalLabels = labels.map(label => label.chartLabel)

  return {
    labels: finalLabels,
    datasets: summarizedDatasets
  }
}




export const createEnergyChartDatasetStructure = (energyData: PVEnergy[], selectedDate: number) => {
  const data = [...energyData];
  data.sort((a: PVEnergy, b: PVEnergy) => new Date(a.time_value).valueOf() - new Date(b.time_value).valueOf())
  let pointer = startOfMonth(selectedDate).valueOf()
  const labels = []
  while (pointer < endOfMonth(selectedDate).valueOf()) {
    labels.push({
      numericValue: startOfDay(pointer).valueOf(),
      chartLabel: format(startOfDay(pointer).valueOf(), 'yyyy-MM-dd')
    })
    pointer += 86400000
  }

  const energy = labels.map((label: {
    numericValue: number,
    chartLabel: string
  }) => {
    const isPresent = data.findIndex((el: PVEnergy) => label.chartLabel === el.time_value)
    if (isPresent !== -1) {
      return {
        daily_inverter1_energy: data[isPresent].daily_inverter1_energy,
        daily_inverter2_energy: data[isPresent].daily_inverter2_energy,
        daily_inverter3_energy: data[isPresent].daily_inverter3_energy,
        daily_inverter4_energy: data[isPresent].daily_inverter4_energy,
        daily_inverter5_energy: data[isPresent].daily_inverter5_energy,
        daily_inverter6_energy: data[isPresent].daily_inverter6_energy,
        daily_inverter7_energy: data[isPresent].daily_inverter7_energy,
        daily_inverter8_energy: data[isPresent].daily_inverter8_energy,
        daily_inverter9_energy: data[isPresent].daily_inverter9_energy,
        daily_inverter10_energy: data[isPresent].daily_inverter10_energy,
        daily_inverter11_energy: data[isPresent].daily_inverter11_energy,
        daily_inverter12_energy: data[isPresent].daily_inverter12_energy,
        daily_inverter13_energy: data[isPresent].daily_inverter13_energy,
        daily_inverter14_energy: data[isPresent].daily_inverter14_energy,
        daily_inverter15_energy: data[isPresent].daily_inverter15_energy,
        daily_inverter16_energy: data[isPresent].daily_inverter16_energy,
        daily_hotel1_inverter_energy: data[isPresent].daily_hotel1_inverter_energy,
        daily_hotel2_inverter_energy: data[isPresent].daily_hotel2_inverter_energy,
        daily_m_inverter1_energy: data[isPresent].daily_m_inverter1_energy,
        n_energy_loss: data[isPresent].n_energy_loss,
        m_energy_loss: data[isPresent].m_energy_loss,
      }
    }
    else {
      return {
        daily_inverter1_energy: 0,
        daily_inverter2_energy: 0,
        daily_inverter3_energy: 0,
        daily_inverter4_energy: 0,
        daily_inverter5_energy: 0,
        daily_inverter6_energy: 0,
        daily_inverter7_energy: 0,
        daily_inverter8_energy: 0,
        daily_inverter9_energy: 0,
        daily_inverter10_energy: 0,
        daily_inverter11_energy: 0,
        daily_inverter12_energy: 0,
        daily_inverter13_energy: 0,
        daily_inverter14_energy: 0,
        daily_inverter15_energy: 0,
        daily_inverter16_energy: 0,
        daily_hotel1_inverter_energy: 0,
        daily_hotel2_inverter_energy: 0,
        daily_m_inverter1_energy: 0,
        n_energy_loss: 0,
        m_energy_loss: 0
      }
    }
  })

  const energySummarized = energy.map(energy => {
    return setPrecision(energy.daily_inverter1_energy +
      energy.daily_inverter2_energy +
      energy.daily_inverter3_energy +
      energy.daily_inverter4_energy +
      energy.daily_inverter5_energy +
      (energy.daily_inverter6_energy || 0) +
      (energy.daily_inverter7_energy || 0) +
      (energy.daily_inverter8_energy || 0) +
      (energy.daily_inverter9_energy || 0) +
      (energy.daily_inverter10_energy || 0) +
      (energy.daily_inverter11_energy || 0) +
      (energy.daily_inverter12_energy || 0) +
      (energy.daily_inverter13_energy || 0) +
      (energy.daily_inverter14_energy || 0) +
      (energy.daily_inverter15_energy || 0) +
      (energy.daily_inverter16_energy || 0) +
      (energy.daily_hotel1_inverter_energy || 0) +
      (energy.daily_hotel2_inverter_energy || 0) +
      (energy.daily_m_inverter1_energy || 0), 2)
  })

  const m_energy_lossSummarized = energy.map(energy => {
    return energy.m_energy_loss > 2.5 ? setPrecision(energy.m_energy_loss, 2) : 0
  })

  const n_energy_lossSummarized = energy.map(energy => {
    return energy.n_energy_loss > 2.5 ? setPrecision(energy.n_energy_loss, 2) : 0
  })

  const energylossTotal = energy.reduce((partialSum, data) => partialSum
    + (data.m_energy_loss > 2.5 ? data.m_energy_loss : 0) + (data.n_energy_loss > 2.5 ? data.n_energy_loss : 0), 0);


    const segmentedEnergyChartDatasets = [{
      label: 'Falownik 1',
      backgroundColor: ['#1f77b4'],  // Blue
      borderColor: ['#1f77b4'],
      borderWidth: 1,
      data: energy.map(energy => setPrecision(energy.daily_inverter1_energy, 2))
    },
    {
      label: 'Falownik 2',
      backgroundColor: ['#ff7f0e'],  // Orange
      borderColor: ['#ff7f0e'],
      borderWidth: 1,
      data: energy.map(energy => setPrecision(energy.daily_inverter2_energy, 2))
    },
    {
      label: 'Falownik 3',
      backgroundColor: ['#2ca02c'],  // Green
      borderColor: ['#2ca02c'],
      borderWidth: 1,
      data: energy.map(energy => setPrecision(energy.daily_inverter3_energy, 2))
    },
    {
      label: 'Falownik 4',
      backgroundColor: ['#d62728'],  // Red
      borderColor: ['#d62728'],
      borderWidth: 1,
      data: energy.map(energy => setPrecision(energy.daily_inverter4_energy, 2))
    },
    {
      label: 'Falownik 5',
      backgroundColor: ['#9467bd'],  // Purple
      borderColor: ['#9467bd'],
      borderWidth: 1,
      data: energy.map(energy => setPrecision(energy.daily_inverter5_energy, 2))
    },
    {
      label: 'Falownik 6 - Alians',
      backgroundColor: ['#8c564b'],  // Brown
      borderColor: ['#8c564b'],
      borderWidth: 1,
      data: energy.map(energy => setPrecision(energy.daily_inverter6_energy, 2))
    },
    {
      label: 'Falownik 7',
      backgroundColor: ['#e377c2'],  // Pink
      borderColor: ['#e377c2'],
      borderWidth: 1,
      data: energy.map(energy => setPrecision(energy.daily_inverter7_energy, 2))
    },
    {
      label: 'Falownik 8',
      backgroundColor: ['#7f7f7f'],  // Gray
      borderColor: ['#7f7f7f'],
      borderWidth: 1,
      data: energy.map(energy => setPrecision(energy.daily_inverter8_energy, 2))
    },
    {
      label: 'Falownik 9',
      backgroundColor: ['#bcbd22'],  // Yellow-green
      borderColor: ['#bcbd22'],
      borderWidth: 1,
      data: energy.map(energy => setPrecision(energy.daily_inverter9_energy, 2))
    },
    {
      label: 'Falownik 10',
      backgroundColor: ['#17becf'],  // Cyan
      borderColor: ['#17becf'],
      borderWidth: 1,
      data: energy.map(energy => setPrecision(energy.daily_inverter10_energy, 2))
    },
    {
      label: 'Falownik 11',
      backgroundColor: ['#ff9896'],  // Light Red
      borderColor: ['#ff9896'],
      borderWidth: 1,
      data: energy.map(energy => setPrecision(energy.daily_inverter11_energy, 2))
    },
    {
      label: 'Falownik 12',
      backgroundColor: ['#c5b0d5'],  // Light Purple
      borderColor: ['#c5b0d5'],
      borderWidth: 1,
      data: energy.map(energy => setPrecision(energy.daily_inverter12_energy, 2))
    },
    {
      label: 'Falownik 13',
      backgroundColor: ['#c49c94'],  // Light Brown
      borderColor: ['#c49c94'],
      borderWidth: 1,
      data: energy.map(energy => setPrecision(energy.daily_inverter13_energy, 2))
    },
    {
      label: 'Falownik 14',
      backgroundColor: ['#f7b6d2'],  // Light Pink
      borderColor: ['#f7b6d2'],
      borderWidth: 1,
      data: energy.map(energy => setPrecision(energy.daily_inverter14_energy, 2))
    },
    {
      label: 'Falownik 15',
      backgroundColor: ['#dbdb8d'],  // Light Yellow-green
      borderColor: ['#dbdb8d'],
      borderWidth: 1,
      data: energy.map(energy => setPrecision(energy.daily_inverter15_energy, 2))
    },
    {
      label: 'Falownik 16',
      backgroundColor: ['#9edae5'],  // Light Cyan
      borderColor: ['#9edae5'],
      borderWidth: 1,
      data: energy.map(energy => setPrecision(energy.daily_inverter16_energy, 2))
    },
    {
      label: 'Fronius M',
      backgroundColor: ['#aec7e8'],  // Light Blue
      borderColor: ['#aec7e8'],
      borderWidth: 1,
      data: energy.map(energy => setPrecision(energy.daily_m_inverter1_energy, 2))
    },
    {
      label: 'Falowniki Hotel 1',
      backgroundColor: ['#ffbb78'],  // Light Orange
      borderColor: ['#ffbb78'],
      borderWidth: 1,
      data: energy.map(energy => setPrecision(energy.daily_hotel1_inverter_energy, 2))
    },
    {
      label: 'Falowniki Hotel 2',
      backgroundColor: ['#98df8a'],  // Light Green
      borderColor: ['#98df8a'],
      borderWidth: 1,
      data: energy.map(energy => setPrecision(energy.daily_hotel2_inverter_energy, 2))
    }]
  

  const finalLabels = labels.map(label => label.chartLabel)

  const summarizedEnergyChartDataset = [{
    label: 'Energia wyprodukowana',
    backgroundColor: [colors.pink],
    borderColor: [colors.pink],
    borderWidth: 1,
    data: energySummarized
  },
  {
    label: 'Energia utracona - N',
    backgroundColor: [colors.darkBlue],
    borderColor: [colors.darkBlue],
    borderWidth: 1,
    data: n_energy_lossSummarized
  },
  {
    label: 'Energia utracona - M',
    backgroundColor: [colors.petrol],
    borderColor: [colors.petrol],
    borderWidth: 1,
    data: m_energy_lossSummarized
  }
  ]

  return {
    labels: finalLabels,
    summarizedEnergy: summarizedEnergyChartDataset,
    segmentedEnergy: segmentedEnergyChartDatasets,
    lostEnergy: setPrecision(energylossTotal, 2)
  }
}

export const createHotelEnergyChartDatasetStructure = (energyData: HotelEnergy[], selectedDate: number) => {
  const data = [...energyData]
  data.sort((a: HotelEnergy, b: HotelEnergy) => new Date(a.time_value).valueOf() - new Date(b.time_value).valueOf())
  let pointer = startOfMonth(selectedDate).valueOf()
  const labels = []
  while (pointer < endOfMonth(selectedDate).valueOf()) {
    labels.push({
      numericValue: startOfDay(pointer).valueOf(),
      chartLabel: format(startOfDay(pointer).valueOf(), 'yyyy-MM-dd')
    })
    pointer += 86400000
  }

  const energy = labels.map((label: {
    numericValue: number,
    chartLabel: string
  }) => {
    const isPresent = data.findIndex((el: HotelEnergy) => label.chartLabel === el.time_value)
    if (isPresent !== -1) {
      return {
        daily_hotel1_engineroom_controlcabinet_energy: data[isPresent].daily_hotel1_engineroom_controlcabinet_energy,
        daily_hotel1_engineroom_gpc_energy: data[isPresent].daily_hotel1_engineroom_gpc_energy,
        daily_hotel1_engineroom_electricfurnace_energy: data[isPresent].daily_hotel1_engineroom_electricfurnace_energy,
        daily_hotel1_engineroom_ppc_energy: data[isPresent].daily_hotel1_engineroom_ppc_energy
      }
    }
    else {
      return {
        daily_hotel1_engineroom_controlcabinet_energy: 0,
        daily_hotel1_engineroom_gpc_energy: 0,
        daily_hotel1_engineroom_electricfurnace_energy: 0,
        daily_hotel1_engineroom_ppc_energy: 0
      }
    }
  })


  const segmentedEnergyChartDatasets = [{
    label: 'Szafa sterująca',
    backgroundColor: ['#41aaaa'],
    borderColor: ['#41aaaa'],
    borderWidth: 1,
    data: energy.map(energy => setPrecision(energy.daily_hotel1_engineroom_controlcabinet_energy, 2))
  },
  {
    label: 'GPC',
    backgroundColor: ['#faa50a'],
    borderColor: ['#faa50a'],
    borderWidth: 1,
    data: energy.map(energy => setPrecision(energy.daily_hotel1_engineroom_gpc_energy, 2))
  },
  {
    label: 'Piece elektryczne',
    backgroundColor: ['#c34673'],
    borderColor: ['#c34673'],
    borderWidth: 1,
    data: energy.map(energy => setPrecision(energy.daily_hotel1_engineroom_electricfurnace_energy, 2))
  },
  {
    label: 'PPC',
    backgroundColor: ['#aab414'],
    borderColor: ['#aab414'],
    borderWidth: 1,
    data: energy.map(energy => setPrecision(energy.daily_hotel1_engineroom_ppc_energy, 2))
  }]

  const finalLabels = labels.map(label => label.chartLabel)

  return {
    labels: finalLabels,
    segmentedEnergy: segmentedEnergyChartDatasets,
  }
}

