import { useEffect, Fragment } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch } from 'react-redux';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import SolarPowerOutlinedIcon from '@mui/icons-material/SolarPowerOutlined';
import EnergySavingsLeafOutlinedIcon from '@mui/icons-material/EnergySavingsLeafOutlined';
import PowerOutlinedIcon from '@mui/icons-material/PowerOutlined';
import { Accordion } from './Accordion.component';
import { AccordionSummary } from './AccordionSummary.component';
import { AccordionDetails } from './AccordionDetails.component';
import { format, formatDuration } from 'date-fns';
import Chip from '@mui/material/Chip';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import Tooltip from '@mui/material/Tooltip';
import { useGetCurrentQuery } from '../services/current';
import { setDialogData } from '../features/AlertDialog/alertDialogSlice';
import { pl } from 'date-fns/locale';

const unitStyle = {
  fontSize: 20
}

const valueStyle = {
  fontSize: 42
}

export const MainPage = () => {
  const { data: currentValues } = useGetCurrentQuery(undefined, { pollingInterval: 4000, refetchOnMountOrArgChange: true });

  const dispatch = useDispatch();

  useEffect(() => {
    const alerts = []
    if (currentValues?.time_value && new Date().valueOf() - new Date(currentValues.time_value).valueOf() > 60000) {
      alerts.push('Nieaktualne dane w bazie danych')
    }
    if (currentValues?.generatorN?.fuel_level && currentValues.generatorN.fuel_level < 40) {
      alerts.push('Poziom paliwa w generatorze 2 mniejszy niż 40 %')
    }
    dispatch(setDialogData({ text: alerts, title: 'Powiadomienia' }))
  }, [currentValues, dispatch])

  const remaining15MinTime = formatDuration({
    minutes: currentValues?.time_value ? 15 - (new Date(currentValues.time_value).getMinutes() % 15) : 0,
  }, {
    locale: pl
  })

  return (
    <Fragment>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ flexGrow: 1 }}>PV</Typography>
          <Typography>Stan na: {currentValues?.time_value ? format(new Date(currentValues.time_value), 'dd/MM/yyyy HH:mm:ss') : '-'}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <Alert severity="success" variant="outlined" sx={valueStyle} icon={<SolarPowerOutlinedIcon fontSize="inherit" />}>
                <AlertTitle>Generowana moc</AlertTitle>
                {currentValues?.currentPvPower || 0} <span style={unitStyle}>kW</span>
                {currentValues?.invertersLimiting && <Tooltip title="Falowniki limitowane"><Chip sx={{ ml: 2 }} icon={<TrendingDownIcon />} color="warning" label="LIM" /></Tooltip>}
              </Alert>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Alert severity="success" variant="outlined" sx={valueStyle} icon={<EnergySavingsLeafOutlinedIcon fontSize="inherit" />}>
                <AlertTitle>Dzisiejsza energia</AlertTitle>
                {currentValues?.dailyPvEnergy || 0} <span style={unitStyle}>kWh</span>
              </Alert>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Alert severity="success" variant="outlined" sx={valueStyle} icon={<EnergySavingsLeafOutlinedIcon fontSize="inherit" />}>
                <AlertTitle>Energia łącznie</AlertTitle>
                {currentValues?.totalPvEnergy || 0} <span style={unitStyle}>MWh</span>
                <Typography variant="body1"></Typography>
              </Alert>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Sieć</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <Alert severity="info" variant="outlined" sx={valueStyle} icon={<PowerOutlinedIcon fontSize="inherit" />}>
                <AlertTitle>Sieć M</AlertTitle>
                {currentValues?.gridAndGeneratorPower1?.current || 0} <span style={unitStyle}>kW</span>
              </Alert>
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <Alert severity="info" variant="outlined" sx={valueStyle} icon={<PowerOutlinedIcon fontSize="inherit" />}>
                <AlertTitle>{currentValues?.generatorN?.charge_voltage && currentValues.generatorN.charge_voltage > 20 ? 'Generator N' : 'Sieć N'}</AlertTitle>
                {currentValues?.gridAndGeneratorPower2?.current || 0} <span style={unitStyle}>kW</span>
              </Alert>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Alert severity="warning" variant="outlined" sx={valueStyle} icon={<PowerOutlinedIcon fontSize="inherit" />}>
                <AlertTitle>Sieć M - 15min (pozostało {remaining15MinTime})</AlertTitle>
                {currentValues?.gridAndGeneratorPower1?.avg15Min || 0} <span style={unitStyle}>kW</span>
              </Alert>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Alert severity="warning" variant="outlined" sx={valueStyle} icon={<PowerOutlinedIcon fontSize="inherit" />}>
                <AlertTitle>Sieć N - 15min (pozostało {remaining15MinTime})</AlertTitle>
                {currentValues?.gridAndGeneratorPower2?.avg15Min || 0} <span style={unitStyle}>kW</span>
              </Alert>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Fragment >
  )
}
