import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { Box, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { Fragment, useEffect, useLayoutEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion } from './Accordion.component';
import { AccordionSummary } from './AccordionSummary.component';
import { AccordionDetails } from './AccordionDetails.component';
import { LineChart } from './LineChart.component';
import { useGetCustomChartDataQuery } from '../services/customCharts';
import { RootState } from '../store';
import { useSelector } from 'react-redux';
import { useSetDatepicker } from '../hooks/useSetDatepicker.hook';
import { colors } from '../utilities/Colors.utility';
import { add, endOfDay, startOfDay, startOfHour, sub } from 'date-fns';

export type TType = 'Power' | 'Pressure' | 'Temperature' | 'Frequency' | 'Current' | 'Percent'

export interface IParameter {
    tableColumnName: string
    tableName: string
    type: TType
    displayName?: string
    selected?: boolean
    unit: string
    multiplier: number
}

interface IParameterWithData extends IParameter {
    data: {
        time_value: number;
        value: number;
    }[];
}

const columns: GridColDef[] = [
    { field: 'parameter', headerName: 'Parametr', width: 300 }
]

const params: IParameter[] = [
    // POWER
    {
        tableColumnName: 'inst_inverter1_power',
        tableName: 'inst',
        displayName: 'Moc Inwerter 1',
        type: 'Power',
        unit: 'W',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_inverter2_power',
        tableName: 'inst',
        displayName: 'Moc Inwerter 2',
        type: 'Power',
        unit: 'W',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_inverter3_power',
        tableName: 'inst',
        displayName: 'Moc Inwerter 3',
        type: 'Power',
        unit: 'W',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_inverter4_power',
        tableName: 'inst',
        displayName: 'Moc Inwerter 4',
        type: 'Power',
        unit: 'W',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_inverter5_power',
        tableName: 'inst',
        displayName: 'Moc Inwerter 5',
        type: 'Power',
        unit: 'W',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_inverter6_power',
        tableName: 'inst',
        displayName: 'Moc Inwerter 6',
        type: 'Power',
        unit: 'W',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_inverter7_power',
        tableName: 'inst',
        displayName: 'Moc Inwerter 7',
        type: 'Power',
        unit: 'W',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_inverter8_power',
        tableName: 'inst',
        displayName: 'Moc Inwerter 8',
        type: 'Power',
        unit: 'W',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_inverter9_power',
        tableName: 'inst',
        displayName: 'Moc Inwerter 9',
        type: 'Power',
        unit: 'W',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_inverter10_power',
        tableName: 'inst',
        displayName: 'Moc Inwerter 10',
        type: 'Power',
        unit: 'W',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_inverter11_power',
        tableName: 'inst',
        displayName: 'Moc Inwerter 11',
        type: 'Power',
        unit: 'W',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_inverter12_power',
        tableName: 'inst',
        displayName: 'Moc Inwerter 12',
        type: 'Power',
        unit: 'W',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_inverter13_power',
        tableName: 'inst',
        displayName: 'Moc Inwerter 13',
        type: 'Power',
        unit: 'W',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_inverter14_power',
        tableName: 'inst',
        displayName: 'Moc Inwerter 14',
        type: 'Power',
        unit: 'W',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_inverter15_power',
        tableName: 'inst',
        displayName: 'Moc Inwerter 15',
        type: 'Power',
        unit: 'W',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_inverter16_power',
        tableName: 'inst',
        displayName: 'Moc Inwerter 16',
        type: 'Power',
        unit: 'W',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_m_grid_generator_power',
        tableName: 'inst',
        displayName: 'Moc sieciowa M',
        type: 'Power',
        unit: 'W',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_n_grid_generator_power',
        tableName: 'inst',
        displayName: 'Moc sieciowa N',
        type: 'Power',
        unit: 'W',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_m_inverter1_power',
        tableName: 'inst',
        displayName: 'Moc Inwerter 1 M',
        type: 'Power',
        unit: 'W',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_hotel1_inverter1_power',
        tableName: 'inst',
        displayName: 'Moc Inwerter 1 Hotel1',
        type: 'Power',
        unit: 'W',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_hotel1_inverter2_power',
        tableName: 'inst',
        displayName: 'Moc Inwerter 2 Hotel1',
        type: 'Power',
        unit: 'W',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_hotel2_inverter1_power',
        tableName: 'inst',
        displayName: 'Moc Inwerter 1 Hotel2',
        type: 'Power',
        unit: 'W',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_hotel2_inverter2_power',
        tableName: 'inst',
        displayName: 'Moc Inwerter 2 Hotel2',
        type: 'Power',
        unit: 'W',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_hotel2_power',
        tableName: 'inst',
        displayName: 'Moc sieciowa Hotel 2',
        type: 'Power',
        unit: 'W',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_hotel1_power',
        tableName: 'inst',
        displayName: 'Moc sieciowa Hotel 1',
        type: 'Power',
        unit: 'W',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_hotel1_engineroom_controlcabinet_power',
        tableName: 'inst',
        displayName: 'Moc szafa sterownicza Hotel 1',
        type: 'Power',
        unit: 'W',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_hotel1_engineroom_gpc_power',
        tableName: 'inst',
        displayName: 'Moc GPC Hotel 1',
        type: 'Power',
        unit: 'W',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_hotel1_engineroom_electricfurnace_power',
        tableName: 'inst',
        displayName: 'Moc Piece elektryczne Hotel 1',
        type: 'Power',
        unit: 'W',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_hotel1_engineroom_ppc_power',
        tableName: 'inst',
        displayName: 'Moc PPC Hotel 1',
        type: 'Power',
        unit: 'W',
        multiplier: 1
    },



    // TEMPERATURE
    {
        tableColumnName: 'inst_n_cooling_infeed_temperature',
        tableName: 'inst',
        displayName: 'Temperatura wejście chłodzenie N',
        type: 'Temperature',
        unit: '°C',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_n_cooling_return_temperature',
        tableName: 'inst',
        displayName: 'Temperatura powrót chłodzenie N',
        type: 'Temperature',
        unit: '°C',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_n_heating_infeed_temperature',
        tableName: 'inst',
        displayName: 'Temperatura wejście grzanie N',
        type: 'Temperature',
        unit: '°C',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_n_heating_return_temperature',
        tableName: 'inst',
        displayName: 'Temperatura powrót grzanie N',
        type: 'Temperature',
        unit: '°C',
        multiplier: 1
    },
    {
        tableColumnName: 'hala31_t_podloza_1',
        tableName: 'hale',
        displayName: 'Temperatura podłoża hala 31',
        type: 'Temperature',
        unit: '°C',
        multiplier: 0.1
    },
    // FREQUENCY
    {
        tableColumnName: 'inst_m_frequency',
        tableName: 'inst',
        displayName: 'Częstotliwość M',
        type: 'Frequency',
        unit: 'Hz',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_n_frequency',
        tableName: 'inst',
        displayName: 'Częstotliwość N',
        type: 'Frequency',
        unit: 'Hz',
        multiplier: 1
    },
    // PRESSURE
    {
        tableColumnName: 'inst_n_cooling_infeed_pressure',
        tableName: 'inst',
        displayName: 'Ciśnienie wejście chłodzenie N',
        type: 'Pressure',
        unit: 'Bar',
        multiplier: 1
    },
    {
        tableColumnName: 'inst_n_cooling_return_pressure',
        tableName: 'inst',
        displayName: 'Ciśnienie powrót chłodzenie N',
        type: 'Pressure',
        unit: 'Bar',
        multiplier: 1
    }, {
        tableColumnName: "tmp_zadana_chlodzenia_parownik",
        tableName: "inst",
        displayName: "Zadana temperatura chłodzenia (Parownik)",
        type: "Temperature",
        unit: "°C",
        multiplier: 0.1
    },
    {
        tableColumnName: "tmp_wejscie_parownik",
        tableName: "inst",
        displayName: "Temperatura wejścia (Parownik)",
        type: "Temperature",
        unit: "°C",
        multiplier: 0.1
    },
    {
        tableColumnName: "tmp_wyjscie_parownik",
        tableName: "inst",
        displayName: "Temperatura wyjścia (Parownik)",
        type: "Temperature",
        unit: "°C",
        multiplier: 0.1
    },
    {
        tableColumnName: "tmp_wejscie_skraplacz",
        tableName: "inst",
        displayName: "Temperatura wejścia (Skraplacz)",
        type: "Temperature",
        unit: "°C",
        multiplier: 0.1
    },
    {
        tableColumnName: "tmp_wyjscie_skraplacz",
        tableName: "inst",
        displayName: "Temperatura wyjścia (Skraplacz)",
        type: "Temperature",
        unit: "°C",
        multiplier: 0.1
    },
    {
        tableColumnName: "tmp_gazu_sprezarka_1",
        tableName: "inst",
        displayName: "Temperatura gazu (Sprężarka 1)",
        type: "Temperature",
        unit: "°C",
        multiplier: 1
    },
    {
        tableColumnName: "tmp_gazu_sprezarka_2",
        tableName: "inst",
        displayName: "Temperatura gazu (Sprężarka 2)",
        type: "Temperature",
        unit: "°C",
        multiplier: 1
    },
    {
        tableColumnName: "cis_gorne_sprezarka_1",
        tableName: "inst",
        displayName: "Ciśnienie górne (Sprężarka 1)",
        type: "Pressure",
        unit: "Bar",
        multiplier: 0.1
    },
    {
        tableColumnName: "cis_gorne_sprezarka_2",
        tableName: "inst",
        displayName: "Ciśnienie górne (Sprężarka 2)",
        type: "Pressure",
        unit: "Bar",
        multiplier: 0.1
    },
    {
        tableColumnName: "cis_dolne_sprezarka_1",
        tableName: "inst",
        displayName: "Ciśnienie dolne (Sprężarka 1)",
        type: "Pressure",
        unit: "Bar",
        multiplier: 0.1
    },
    {
        tableColumnName: "cis_dolne_sprezarka_2",
        tableName: "inst",
        displayName: "Ciśnienie dolne (Sprężarka 2)",
        type: "Pressure",
        unit: "Bar",
        multiplier: 0.1
    },
    {
        tableColumnName: "prad_sprezarka_1",
        tableName: "inst",
        displayName: "Prąd (Sprężarka 1)",
        type: "Current",
        unit: "A",
        multiplier: 1
    },
    {
        tableColumnName: "prad_sprezarka_2",
        tableName: "inst",
        displayName: "Prąd (Sprężarka 2)",
        type: "Current",
        unit: "A",
        multiplier: 1
    },
    {
        tableColumnName: "moc_chlodzenia_dostepna",
        tableName: "inst",
        displayName: "Dostępna moc chłodzenia",
        type: "Percent",
        unit: "%",
        multiplier: 1
    },
    {
        tableColumnName: "moc_chlodzenia_czynna",
        tableName: "inst",
        displayName: "Czynna moc chłodzenia",
        type: "Percent",
        unit: "%",
        multiplier: 1
    }, {
        tableColumnName: "pompa_obiegowa_skraplacz_hz_zadane",
        tableName: "inst",
        displayName: "Zadana częstotliwość (Pompa obiegowa skraplacz)",
        type: "Frequency",
        unit: "Hz",
        multiplier: 0.01
    },
    {
        tableColumnName: "pompa_obiegowa_skraplacz_hz_rzeczywiste",
        tableName: "inst",
        displayName: "Rzeczywista częstotliwość (Pompa obiegowa skraplacz)",
        type: "Frequency",
        unit: "Hz",
        multiplier: 0.01
    },
    {
        tableColumnName: "pompa_obiegowa_skraplacz_prad",
        tableName: "inst",
        displayName: "Prąd (Pompa obiegowa skraplacz)",
        type: "Current",
        unit: "A",
        multiplier: 1
    },
    {
        tableColumnName: "pompa_obiegowa_parownik_hz_zadane",
        tableName: "inst",
        displayName: "Zadana częstotliwość (Pompa obiegowa parownik)",
        type: "Frequency",
        unit: "Hz",
        multiplier: 0.01
    },
    {
        tableColumnName: "pompa_obiegowa_parownik_hz_rzeczywiste",
        tableName: "inst",
        displayName: "Rzeczywista częstotliwość (Pompa obiegowa parownik)",
        type: "Frequency",
        unit: "Hz",
        multiplier: 0.01
    },
    {
        tableColumnName: "pompa_obiegowa_parownik_prad",
        tableName: "inst",
        displayName: "Prąd (Pompa obiegowa parownik)",
        type: "Current",
        unit: "A",
        multiplier: 1
    },
    {
        tableColumnName: "pompa_obiegowa_odzysk_ciepla_hz_zadane",
        tableName: "inst",
        displayName: "Zadana częstotliwość (Pompa obiegowa odzysk ciepła)",
        type: "Frequency",
        unit: "Hz",
        multiplier: 0.01
    },
    {
        tableColumnName: "pompa_obiegowa_odzysk_ciepla_hz_rzeczywiste",
        tableName: "inst",
        displayName: "Rzeczywista częstotliwość (Pompa obiegowa odzysk ciepła)",
        type: "Frequency",
        unit: "Hz",
        multiplier: 0.01
    },
    {
        tableColumnName: "pompa_obiegowa_odzysk_ciepla_prad",
        tableName: "inst",
        displayName: "Prąd (Pompa obiegowa odzysk ciepła)",
        type: "Current",
        unit: "A",
        multiplier: 1
    }

]

export const CustomCharts = () => {
    const { time } = useSelector((state: RootState) => state.toolbar);
    const [allParams, setAllParams] = useState<IParameter[]>(params);
    const [valueType, setValueType] = useState<string>('1');

    useEffect(() => {
        const paramsInLocalStorage = localStorage.getItem('params');
        if (!paramsInLocalStorage) {
            return;
        }

        setAllParams(currentParams => currentParams.map(param => {
            const savedParams: IParameter[] = JSON.parse(paramsInLocalStorage);
            const existingSavedParam = savedParams.find(savedParam => param.tableColumnName === savedParam.tableColumnName)
            return existingSavedParam ? existingSavedParam : param
        }))
    }, [])

    const getDateType = () => {
        switch (valueType) {
            case '1': {
                return {
                    datePickerType: 'current',
                    timeFrom: time.valueOf() - 300000,
                    timeTo: time.valueOf()
                }
            }
            case '60': {
                return {
                    datePickerType: '1min',
                    timeFrom: new Date(startOfHour(sub(time, { hours: 1 }))).valueOf(),
                    timeTo: startOfHour(add(time, { hours: 1 })).valueOf()
                }
            }
            case '600': {
                return {
                    datePickerType: '10min',
                    timeFrom: new Date(startOfDay(time)).valueOf(),
                    timeTo: new Date(endOfDay(time)).valueOf()
                }
            }
            default: {
                return {
                    datePickerType: 'current',
                    timeFrom: time.valueOf() - 300000,
                    timeTo: time.valueOf()

                }
            }
        }
    }

    const dateType = getDateType();
    useSetDatepicker(dateType.datePickerType);

    const selectedParams = allParams.filter(param => param.selected);

    const paramMap = new Map();
    allParams.forEach(param => {
        if (param.selected) {
            const existing = paramMap.get(param.type);
            if (existing) {
                paramMap.set(param.type, [...existing, param])
            } else {
                paramMap.set(param.type, [param])
            }
        }
    })

    const { data } = useGetCustomChartDataQuery({
        params: selectedParams,
        timeFrom: dateType.timeFrom,
        timeTo: dateType.timeTo,
        aggregation: valueType
    }, {
        refetchOnMountOrArgChange: true
    });

    const localizeYAxisLabel = (type: TType) => {
        switch (type) {
            case 'Power': {
                return 'Moc'
            }
            case 'Frequency': {
                return 'Częstotliwość'
            }
            case 'Pressure': {
                return 'Ciśnienie'
            }
            case 'Temperature': {
                return 'Temperatura'
            }
            default: {
                return ''
            }
        }
    }

    let index = 0;
    const mapDatasets = (values: IParameterWithData[], yAxisID: string = 'y') => {
        return values.map(value => {
            const data = {
                label: value.displayName,
                yAxisID,
                data: value.data.map(val => {
                    return {
                        x: val.time_value,
                        y: val.value
                    }
                }),
                unit: value.unit,
                backgroundColor: colorsArray[index % (colorsArray.length - 1)],
                borderColor: colorsArray[index % (colorsArray.length - 1)],
                fill: false,
                lineTension: 0.3
            }

            index++;
            return data
        })
    }

    const axisUsed = paramMap.size;

    const axisY1Values = (Object.values(data || {})?.[0] || []);
    const axisY2Values = (Object.values(data || {})?.[1] || []);
    const axisY1Label = localizeYAxisLabel(axisY1Values?.[0]?.type);
    const axisY2Label = localizeYAxisLabel(axisY2Values?.[0]?.type);
    const colorsArray = Object.values(colors);

    const datasets = [...mapDatasets(axisY1Values), ...mapDatasets(axisY2Values, 'y1')]

    const ParamTable = (rows: { id: number, parameter: string }[], isRowSelectable: boolean, paramType: TType, borderColor: string) => {
        return (
            <DataGrid
                sx={{
                    boxShadow: 0,
                    border: 2,
                    borderColor: borderColor,
                    '& .MuiDataGrid-cell:hover': {
                        color: 'primary.main',
                    },
                }}
                localeText={{
                    MuiTablePagination: {
                        labelDisplayedRows: ({ from, to, count }) =>
                            `${from} - ${to} z ${count}`,
                        labelRowsPerPage: 'Wierszy na stronę',

                    },
                    // Rows selected footer text
                    footerRowSelected: (count) =>
                        count !== 1
                            ? `${count.toLocaleString()} wybranych`
                            : `${count.toLocaleString()} wybrany`,
                    toolbarQuickFilterPlaceholder: 'Szukaj',
                    toolbarExport: 'Eksport',
                    columnMenuSortAsc: 'Sortuj rosnąco',
                    columnMenuSortDesc: 'Sortuj malejąco',
                    columnMenuUnsort: 'Domyślnie'
                }}
                rows={rows}
                density='compact'
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },

                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                isRowSelectable={() => isRowSelectable}
                rowSelectionModel={allParams.filter(param => param.type === paramType).map((param, index) => {
                    if (param.selected) {
                        return index
                    }
                    return 'placeholder'
                }).filter(index => index !== 'placeholder')
                }
                onRowSelectionModelChange={(row) => {
                    setAllParams(selected => {
                        let typeIndex = -1;
                        const newSelected = selected.map((param) => {
                            if (param.type === paramType) {
                                typeIndex++;
                                return {
                                    ...param,
                                    selected: row.includes(typeIndex) ? true : false
                                }
                            }
                            return param;
                        })
                        localStorage.setItem('params', JSON.stringify(newSelected))
                        return newSelected;
                    })
                }}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true
                    },
                }}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector

            />)
    }

    const filterRowsOfType = (type: TType) => {
        return allParams.filter(param => param.type === type).map((param, index) => {
            return {
                id: index,
                parameter: param.displayName || param.tableColumnName
            }
        })
    }

    return (
        <Fragment>
            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Parametry</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box mb={2} ml={2} mr={2}>
                        <FormControl>
                            <FormLabel id="value-type">Typ danych</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="value-type"
                                name="row-radio-buttons-group"
                                value={valueType}
                                onChange={(event) => setValueType((event.target as HTMLInputElement).value)}
                            >
                                <FormControlLabel value="1" control={<Radio />} label="Sekundowe" />
                                <FormControlLabel value="60" control={<Radio />} label="Średnie 1-min" />
                                <FormControlLabel value="600" control={<Radio />} label="Średnie 10-min" />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    <Grid container spacing={2}>
                        {/* Power */}
                        <Grid item xs={12} sm={6}>
                            {ParamTable(filterRowsOfType('Power'), axisUsed < 2 || paramMap.get('Power')?.length > 0, 'Power', colors.lightOrange)}
                        </Grid>
                        {/* Pressure */}
                        <Grid item xs={12} sm={6}>
                            {ParamTable(filterRowsOfType('Pressure'), axisUsed < 2 || paramMap.get('Pressure')?.length > 0, 'Pressure', colors.petrol)}
                        </Grid>
                        {/* Frequency */}
                        <Grid item xs={12} sm={6}>
                            {ParamTable(filterRowsOfType('Frequency'), axisUsed < 2 || paramMap.get('Frequency')?.length > 0, 'Frequency', colors.pink)}
                        </Grid>
                        {/* Temperature */}
                        <Grid item xs={12} sm={6}>
                            {ParamTable(filterRowsOfType('Temperature'), axisUsed < 2 || paramMap.get('Temperature')?.length > 0, 'Temperature', colors.lightGreen)}
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Box mt={2}>
                <LineChart data={{
                    datasets: datasets
                }}
                    y1AxisTitle={axisY1Label}
                    yAxisTitle={axisY2Label}
                />
            </Box>
        </Fragment>
    )
}