import { useEffect } from "react"
import { useDispatch } from "react-redux";
import { setAutoRefresh, setTimePicker } from "../features/toolbar/toolbarSlice";

export const useSetDatepicker = (type: string) => {
    const dispatch = useDispatch();

    useEffect(() => {
        switch (type) {
            case 'current': {
                dispatch(setTimePicker({
                    type: 'datetime',
                    views: ['year', 'month', 'day', 'hours', 'minutes'],
                    skipValue: {
                        unit: 'minutes',
                        value: 4
                    }
                }))
                break;
            }
            case '1min': {
                dispatch(setTimePicker({
                    type: 'datetime',
                    views: ['year', 'month', 'day', 'hours'],
                    skipValue: {
                        unit: 'hours',
                        value: 1
                    }
                }))
                break;
            }
            case '10min': {
                dispatch(setAutoRefresh(false))
                dispatch(setTimePicker({
                    type: 'date',
                    views: ['year', 'month', 'day'],
                    skipValue: {
                        unit: 'days',
                        value: 1
                    }
                }))
                break;
            }
            case 'day': {
                dispatch(setAutoRefresh(false))
                dispatch(setTimePicker({
                    type: 'date',
                    views: ['year', 'month'],
                    skipValue: {
                        unit: 'months',
                        value: 1
                    }
                }))
                break;
            }
        }
    }, [dispatch, type])
}