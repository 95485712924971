import { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { mapHotelsPowerToChart } from '../../../utilities/CreateChartDataStructure.utility';
import { LineChart } from '../../LineChart.component';
import { colors } from '../../../utilities/Colors.utility';
import { add, startOfHour, sub } from 'date-fns';
import { useInterval } from '../../../hooks/useInterval.hook';
import { useSetDatepicker } from '../../../hooks/useSetDatepicker.hook';
import { RootState } from '../../../store';
import { useGetHotelPowerQuery } from '../../../services/power';

export const Hotel1MinuteAvg = () => {
    const [chartHotel1GridPower1Min, setChartHotel1GridPower1Min] = useState<Array<{ x: number, y: number }>>([]);
    const [chartHotel1PvPower1Min, setChartHotel1PvPower1Min] = useState<Array<{ x: number, y: number }>>([]);
    const [chartHotel1Power1Min, setChartHotel1Power1Min] = useState<Array<{ x: number, y: number }>>([]);
    const [chartHotel2GridPower1Min, setChartHotel2GridPower1Min] = useState<Array<{ x: number, y: number }>>([]);
    const [chartHotel2PvPower1Min, setChartHotel2PvPower1Min] = useState<Array<{ x: number, y: number }>>([]);
    const [chartHotel2Power1Min, setChartHotel2Power1Min] = useState<Array<{ x: number, y: number }>>([]);

    const { time } = useSelector((state: RootState) => state.toolbar);
    const { data } = useGetHotelPowerQuery({ type: '1minAggregated', timeFrom: new Date(startOfHour(sub(time, { hours: 1 }))).valueOf() - 300000, timeTo: startOfHour(add(time, { hours: 1 })).valueOf() },
        { refetchOnMountOrArgChange: true })

    useInterval(60000);
    useSetDatepicker('1min');

    useEffect(() => {
        if (data) {
            const mapped = mapHotelsPowerToChart(data)
            setChartHotel1GridPower1Min(mapped.hotel1GridPower)
            setChartHotel1PvPower1Min(mapped.hotel1PvPower)
            setChartHotel1Power1Min(mapped.hotel1Power)
            setChartHotel2GridPower1Min(mapped.hotel2GridPower)
            setChartHotel2PvPower1Min(mapped.hotel2PvPower)
            setChartHotel2Power1Min(mapped.hotel2Power)
        }
    }, [setChartHotel1GridPower1Min, setChartHotel1PvPower1Min, setChartHotel1Power1Min, data])

    return (
        <Fragment>
            <LineChart
                chartTitle='Moce średnie 1-minutowe'
                data={{
                    datasets: [
                        {
                            label: 'Moc sieciowa - 1',
                            backgroundColor: colors.petrol,
                            borderColor: colors.petrol,
                            fill: false,
                            lineTension: 0.3,
                            data: chartHotel1GridPower1Min,
                            unit: 'W'
                        },
                        {
                            label: 'Moc falowników - 1',
                            backgroundColor: colors.lightOrange,
                            borderColor: colors.lightOrange,
                            fill: false,
                            lineTension: 0.3,
                            data: chartHotel1PvPower1Min,
                            unit: 'W'
                        },
                        {
                            label: 'Moc hotelu - 1',
                            backgroundColor: colors.lighViolet,
                            borderColor: colors.lighViolet,
                            fill: false,
                            lineTension: 0.3,
                            data: chartHotel1Power1Min,
                            unit: 'W'
                        },
                        {
                            label: 'Moc sieciowa - 2',
                            backgroundColor: colors.darkBlue,
                            borderColor: colors.darkBlue,
                            fill: false,
                            lineTension: 0.3,
                            data: chartHotel2GridPower1Min,
                            borderDash: [3, 1],
                            unit: 'W'
                        },
                        {
                            label: 'Moc falowników - 2',
                            backgroundColor: colors.darkOrange,
                            borderColor: colors.darkOrange,
                            fill: false,
                            lineTension: 0.3,
                            data: chartHotel2PvPower1Min,
                            borderDash: [3, 1],
                            unit: 'W'
                        },
                        {
                            label: 'Moc hotelu - 2',
                            backgroundColor: colors.darkViolet,
                            borderColor: colors.darkViolet,
                            fill: false,
                            lineTension: 0.3,
                            data: chartHotel2Power1Min,
                            borderDash: [3, 1],
                            unit: 'W'
                        }
                    ],
                }}
                yAxisTitle='Moc'
            />
        </Fragment>
    )
}