// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export interface PlantsEnergy {
    daily_m_grid_energy: number
    daily_n_grid_energy: number
    daily_hotel1_import_energy: number
    daily_hotel1_export_energy: number
    daily_hotel2_import_energy: number
    daily_hotel2_export_energy: number
    daily_plant_n_energy: number
    daily_plant_m_energy: number
    time_value: string
}

export interface HotelEnergy {
    daily_hotel1_engineroom_controlcabinet_energy: number
    daily_hotel1_engineroom_gpc_energy: number
    daily_hotel1_engineroom_electricfurnace_energy: number
    daily_hotel1_engineroom_ppc_energy: number
    time_value: string
}

export interface PVEnergy {
    daily_inverter1_energy: number
    daily_inverter2_energy: number
    daily_inverter3_energy: number
    daily_inverter4_energy: number
    daily_inverter5_energy: number
    daily_inverter6_energy: number
    daily_inverter7_energy: number
    daily_inverter8_energy: number
    daily_inverter9_energy: number
    daily_inverter10_energy: number
    daily_inverter11_energy: number
    daily_inverter12_energy: number
    daily_inverter13_energy: number
    daily_inverter14_energy: number
    daily_inverter15_energy: number
    daily_inverter16_energy: number
    daily_hotel1_inverter_energy: number
    daily_hotel2_inverter_energy: number
    daily_m_inverter1_energy: number
    n_energy_loss: number
    m_energy_loss: number
    time_value: string
}

export type EnergyObject = 'plants' | 'hotel1' | 'hotel2' | 'pv'

export const energyApi = createApi({
    reducerPath: 'energyApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/energy/' }),
    endpoints: (builder) => ({
        getEnergy: builder.query<PlantsEnergy[] | HotelEnergy[] | PVEnergy[], { type: EnergyObject, timeFrom: string, timeTo: string }>({
            query: (data) => `${data.type}/${data.timeFrom}/${data.timeTo}`
        })
    }),
})

export const { useGetEnergyQuery } = energyApi