import { useState, useEffect, useCallback } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DrawerRoutes from '../routes/Drawer.route';
import { Link, useLocation } from "react-router-dom";
import HomeIcon from '@mui/icons-material/HomeRounded';
import { useSelector, useDispatch } from 'react-redux';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { CalendarPickerView, MobileDatePicker, MobileDateTimePicker } from '@mui/x-date-pickers';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import FastForwardIcon from '@mui/icons-material/FastForward';
import add from 'date-fns/add';
import sub from 'date-fns/sub';
import Box from '@mui/material/Box';
import ReportProblemIcon from '@mui/icons-material/ReportProblemOutlined';
import Badge from '@mui/material/Badge';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { CalendarOrClockPickerView } from '@mui/x-date-pickers/internals/models';
import SettingsIcon from '@mui/icons-material/Settings';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
import { RootState } from '../store';
import { ITimeSkip, setAutoRefresh, setDate } from '../features/toolbar/toolbarSlice';
import { setDialogOpen } from '../features/AlertDialog/alertDialogSlice';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';

const noPickerRoutes = ['/generator', '/ustawienia', '/', '/logi']

export const MainDrawer = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const selectedDate = useSelector((state: RootState) => state.toolbar.time);
  const timePicker = useSelector((state: RootState) => state.toolbar.timePicker);
  const alerts = useSelector((state: RootState) => state.alertDialog.text);
  const [open, setOpen] = useState(false);
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const timeForward = useCallback((time: ITimeSkip) => {
    dispatch(setAutoRefresh(false))
    dispatch(setDate(add(selectedDate, {
      [time.unit]: time.value
    }).valueOf()))
  }, [dispatch, selectedDate])

  const timeBackward = useCallback((time: ITimeSkip) => {
    dispatch(setAutoRefresh(false))
    dispatch(setDate(sub(selectedDate, {
      [time.unit]: time.value
    }).valueOf()))
  }, [dispatch, selectedDate])

  useEffect(() => {
    const keyDownListener = (e: any) => {
      if (e.key === 'ArrowLeft') {
        timeBackward(timePicker.skipValue)
      }
      if (e.key === 'ArrowRight') {
        timeForward(timePicker.skipValue)
      }
    }
    if (location.pathname !== '/' && location.pathname !== '/generator') {
      window.addEventListener('keydown', keyDownListener)
      document.getElementById('main-body')?.focus()
    }

    return () => window.removeEventListener('keydown', keyDownListener)
  }, [timeBackward, timeForward, location.pathname, timePicker.skipValue])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            edge="start"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
            IoT
          </Typography>
          <IconButton onClick={() => timeBackward(timePicker.skipValue)} color="inherit" aria-label="fast rewind" sx={{
            display: noPickerRoutes.includes(location.pathname) ? 'none' : 'inline-flex'
          }}>
            <FastRewindIcon />
          </IconButton>
          <IconButton onClick={() => timeForward(timePicker.skipValue)} color="inherit" aria-label="fast forward" sx={{
            display: noPickerRoutes.includes(location.pathname) ? 'none' : 'inline-flex'
          }}>
            <FastForwardIcon />
          </IconButton>
          <IconButton aria-label="set time range" onClick={() => setDatePickerOpen(true)} color="inherit" sx={{
            display: noPickerRoutes.includes(location.pathname) ? 'none' : 'inline-flex'
          }}>
            <DateRangeIcon />
          </IconButton>
          <IconButton
            size="large"
            color="inherit"
            sx={{
              display: location.pathname === '/' ? 'inline-flex' : 'none'
            }}
            onClick={() => {
              dispatch(setDialogOpen())
            }}
            disabled={!alerts.length}
          >
            <Badge badgeContent={alerts.length} color="secondary">
              <ReportProblemIcon />
            </Badge>
          </IconButton>
          {timePicker.type === 'date' ?
            <MobileDatePicker
              renderInput={(params) => <span></span>}
              openTo="month"
              disableFuture
              open={datePickerOpen}
              onOpen={() => setDatePickerOpen(true)}
              onClose={() => setDatePickerOpen(false)}
              value={selectedDate}
              views={timePicker.views as CalendarPickerView[]}
              onChange={(date: any) => {
                dispatch(setDate(date.valueOf()))
                dispatch(setAutoRefresh(false))
              }}
              closeOnSelect
              toolbarTitle='Wybierz datę'
            /> :
            <MobileDateTimePicker
              renderInput={(props) => <span></span>}
              ampm={false}
              disableFuture
              open={datePickerOpen}
              onOpen={() => setDatePickerOpen(true)}
              onClose={() => setDatePickerOpen(false)}
              value={selectedDate}
              views={timePicker.views as CalendarOrClockPickerView[]}
              onChange={(date: any) => {
                dispatch(setDate(date.valueOf()))
                dispatch(setAutoRefresh(false))
              }}
              toolbarTitle='Wybierz datę'
              closeOnSelect
            />
          }
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        disableSwipeToOpen
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setOpen(false)}
        >
          <List >
            <ListItem button component={Link} to="/">
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary={'Strona główna'} />
            </ListItem>
            <ListItem button component={Link} to="/moc/zaklady">
              <ListItemIcon>
                <ShowChartIcon />
              </ListItemIcon>
              <ListItemText primary={'Raporty mocy'} />
            </ListItem>
            <ListItem button component={Link} to="/energia/zaklady">
              <ListItemIcon>
                <BarChartIcon />
              </ListItemIcon>
              <ListItemText primary={'Raporty energii'} />
            </ListItem>
            <ListItem button component={Link} to="/ai">
              <ListItemIcon>
                <AutoAwesomeIcon />
              </ListItemIcon>
              <ListItemText primary={'AI'} />
            </ListItem>
            <ListItem button component={Link} to="/wykresy  ">
              <ListItemIcon>
                <AutoGraphIcon />
              </ListItemIcon>
              <ListItemText primary={'Wykresy'} />
            </ListItem>
            <ListItem button component={Link} to="/generator">
              <ListItemIcon>
                <LocalGasStationIcon />
              </ListItemIcon>
              <ListItemText primary={'Generator'} />
            </ListItem>
            <ListItem button component={Link} to="/ustawienia">
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary={'Ustawienia'} />
            </ListItem>
            <ListItem button component={Link} to="/logi">
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary={'Logi'} />
            </ListItem>
          </List>
        </Box>
      </SwipeableDrawer>
      <Box
        onClick={() => setOpen(false)}
        sx={{ backgroundColor: 'background.paper', minHeight: matches ? `calc(100vh - 64px)` : `calc(100vh - 56px)` }}>
        <DrawerRoutes />
      </Box>
    </Box>
  );
}