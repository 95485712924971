import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Fragment, useState } from 'react';
import Box from '@mui/material/Box';
import { SummarizedEnergy } from './SummarizedEnergy.component';
import { SegmentedEnergy } from './SegmentedEnergy.component';

type ChartType = 'segmented' | 'summarized';

export const PlantEnergy = () => {
  const [tabValue, setTabValue] = useState<ChartType>('summarized');

  const getComponent = (viewType: ChartType): React.ReactNode => {
    switch (viewType) {
      case 'summarized': {
        return <SummarizedEnergy />
      }
      case 'segmented': {
        return <SegmentedEnergy />
      }
      default: {
        return null;
      }
    }
  }

  return (
    <Fragment>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} variant="scrollable" scrollButtons="auto">
          <Tab label="Sumaryczne" onClick={() => setTabValue('summarized')} value='summarized' />
          <Tab disabled label="Cząstkowe" onClick={() => setTabValue('segmented')} value='segmented' />
        </Tabs>
      </Box>
      {getComponent(tabValue)}
    </Fragment>
  )
}