// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IParameter } from '../components/CustomCharts.component';

interface ICustomChartResponse { 
    [type: string]: (IParameter & {
        data: {
            time_value: number
            value: number
        }[]
    })[]
}

interface IGetChartDataBody {
    params: IParameter[], 
    timeFrom: number, 
    timeTo: number, 
    aggregation: string
}

export const customChartsApi = createApi({
    reducerPath: 'customChartsApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/' }),
    tagTypes: ['Post'],
    endpoints: (builder) => ({
        getCustomChartData: builder.query<ICustomChartResponse, IGetChartDataBody>({
            query: (data) => ({
                body: {
                    params: data.params,
                    timeFrom: data.timeFrom,
                    timeTo: data.timeTo,
                    aggregation: data.aggregation
                },
                url: 'charts',
                method: 'POST',
            }),
        })
    }),
})

export const { useGetCustomChartDataQuery } = customChartsApi