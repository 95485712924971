import Chart from 'chart.js/auto';
import { ChartType } from 'chart.js';
import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { pl } from 'date-fns/locale';
import { useUpdateChart } from '../hooks/useUpdateChart.hook';

interface LineChartProps {
  data: {
    datasets: Array<{
      label?: string,
      backgroundColor: string
      borderColor: string,
      lineTension: number,
      fill: boolean,
      borderDash?: Array<number>,
      borderDashOffset?: number,
      borderJoinStyle?: string,
      data: Array<{
        x: number | Date,
        y: number
      }>,
      pointRadius?: number,
      hidden?: boolean,
      yAxisID?: string,
      unit: string
    }>
  },
  timeInterval?: string,
  chartTitle?: string,
  xAxisTitle?: string,
  yAxisTitle?: string,
  y1AxisTitle?: string,
  tooltipFormat?: string,
  yAxisRange?: {
    min: number,
    max: number
  }
}

export const LineChart: React.FC<LineChartProps> = ({ data, chartTitle, xAxisTitle, yAxisTitle, y1AxisTitle, timeInterval, tooltipFormat, yAxisRange }) => {
  const chartContainer = useRef() as React.MutableRefObject<HTMLCanvasElement>;
  const [chartInstance, setChartInstance] = useState<any>(null);
  const theme = useTheme();

  useUpdateChart(chartInstance, data, yAxisTitle, y1AxisTitle)
  const chartType: ChartType = 'line'

  const chartConfig = useMemo(() => {
    return {
      type: chartType,
      data,
      options: {
        responsive: true,
        aspectRatio: window.innerWidth <= 768 ? 0.8 : 2.5,
        interaction: {
          intersect: false,
          mode: 'index',
        },
        elements: {
          point: {
            radius: 1
          }
        },
        plugins: {
          title: {
            display: chartTitle ? true : false,
            text: chartTitle ? chartTitle : '',
            fontColor: theme.palette.text.primary,
            color: theme.palette.text.primary
          },
          legend: {
            labels: {
              color: theme.palette.text.primary,
              font: {
                size: window.innerWidth <= 768 ? 12 : 14,
              },
              boxWidth: window.innerWidth <= 768 ? 20 : 40
            },
            title: {
              color: theme.palette.text.primary
            }
          },
          tooltip: {
            callbacks: {
              label: function (context: any) {
                let label = context.dataset.label || '';
                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += context.parsed.y
                }
                if (label && context.dataset.unit) {
                  label += ` ${context.dataset.unit}`;
                }
                return label;
              }
            }
          },
        },
        scales: {
          x: {
            type: 'time',
            title: {
              display: true,
              text: xAxisTitle ? xAxisTitle : '',
              font: {
                size: 18,
                weight: 400
              },
            },
            ticks: {
              color: theme.palette.text.primary,

            },
            time: {
              displayFormats: {
                millisecond: 'HH:mm:ss.SSS',
                second: 'HH:mm:ss',
                minute: 'HH:mm',
                hour: 'HH',
              },
              tooltipFormat: tooltipFormat ? tooltipFormat : 'PPpp',
              unit: timeInterval ? timeInterval : false
            },
            adapters: {
              date: {
                locale: pl
              }
            },
          },
          y: {
            title: {
              display: window.innerWidth <= 768 ? false : true,
              text: yAxisTitle ? yAxisTitle : '',
              font: {
                size: 12,
                weight: 400
              },
            },
            ticks: {
              color: theme.palette.text.primary
            },
            suggestedMin: yAxisRange ? yAxisRange.min : null,
            suggestedMax: yAxisRange ? yAxisRange.max :null,
            position: 'left'
          },
          y1: {
            title: {
              display: window.innerWidth <= 768 ? false : true,
              text: y1AxisTitle ? y1AxisTitle : '',
              font: {
                size: 12,
                weight: 400
              },
            },
            type: 'linear',
            display: data.datasets.some(dataset => dataset.yAxisID)? true : false,
            position: 'right',
    
            // grid line settings
            grid: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
          },
        },
      },
    }
  }, [data, chartTitle, theme.palette.text.primary, xAxisTitle, yAxisTitle, timeInterval, tooltipFormat, yAxisRange, y1AxisTitle]);

  useEffect(() => {
    setChartInstance(new Chart(chartContainer.current, chartConfig as any))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      <canvas ref={chartContainer} tabIndex={0} />
    </React.Fragment>
  )
}