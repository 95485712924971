import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { setDialogClose } from '../features/AlertDialog/alertDialogSlice';

export const AlertDialog = () => {
  const dispatch = useDispatch();
  const {open, title, text} = useSelector((state: RootState) => state.alertDialog);

  return (
    <Dialog
      open={open}
      onClose={() => dispatch(setDialogClose())}
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        <List>
          {text.map((txt, index) => <ListItem disableGutters disablePadding key={index}>
            <ListItemButton>
              <ListItemText primary={txt} />
            </ListItemButton>
          </ListItem>)}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(setDialogClose())} autoFocus>
          Zamknij
        </Button>
      </DialogActions>
    </Dialog>
  );
}
