import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { setDate } from "../features/toolbar/toolbarSlice";

export const useInterval = (time: number) => {
    const autoRefresh = useSelector((state: RootState) => state.toolbar.autoRefresh);
    const dispatch = useDispatch();

    useEffect(() => {
        let interval: any = null

        if (autoRefresh) {
            dispatch(setDate(new Date().valueOf()));

            interval = setInterval(() => {
                dispatch(setDate(new Date().valueOf()))
            }, time)
        }
        else {
            clearInterval(interval)
        }
        return () => clearInterval(interval)
    }, [dispatch, autoRefresh, time])
}

