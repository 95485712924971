import Box from "@mui/material/Box"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormGroup from "@mui/material/FormGroup"
import { format } from "date-fns"
import { Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Chip from '@mui/material/Chip';
import { Alert, AlertTitle, Checkbox } from "@mui/material"
import SettingsIcon from '@mui/icons-material/Settings';
import { RootState } from "../../store"
import { setAutoRefresh } from "../../features/toolbar/toolbarSlice"

export const Settings = () => {
    const autoRefresh = useSelector((state: RootState) => state.toolbar.autoRefresh);
    const selectedDate = useSelector((state: RootState) => state.toolbar.time);
    const dispatch = useDispatch();

    return (
        <Fragment>
            <Box pl={1} pr={1} pb={1} sx={{ textAlign: 'end' }}>
                <Chip icon={<AccessTimeIcon />} label={format(selectedDate, 'dd-MM-yyyy')} variant="outlined" />
            </Box>
            <Alert severity="success" icon={<SettingsIcon fontSize="inherit" />} >
                <AlertTitle>Ustawienia</AlertTitle>
                <FormGroup row>
                    <FormControlLabel control={<Checkbox checked={autoRefresh}
                        onChange={(event) => dispatch(setAutoRefresh(event.target.checked))}
                        color="success" />} label="Odświeżanie"
                    />
                </FormGroup>
            </Alert>
        </Fragment>
    )
}