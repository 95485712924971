import { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { createPowerChartDatasetStructure } from '../../../utilities/CreateChartDataStructure.utility';
import { LineChart } from '../../LineChart.component';
import { colors } from '../../../utilities/Colors.utility';
import { endOfDay, startOfDay } from 'date-fns';
import { setPrecision } from '../../../utilities/SetPrecision.utility';
import { useSetDatepicker } from '../../../hooks/useSetDatepicker.hook';
import { RootState } from '../../../store';
import { Power, PowerLoss, useGetPowerQuery } from '../../../services/power';

export const PlantLimitedPower = () => {
    const [chartLimitedPowerDatasets, setChartLimitedPowerDatasets] = useState<Array<{
        x: number,
        y: number
    }>>([]);

    const { time } = useSelector((state: RootState) => state.toolbar);
    const { data: power10MinAggregated } = useGetPowerQuery({
        type: '10minAggregated',
        timeFrom: new Date(startOfDay(time)).valueOf(),
        timeTo: new Date(endOfDay(time)).valueOf()
    }, { refetchOnMountOrArgChange: true })

    const { data: powerSum } = useGetPowerQuery({
        type: 'limited',
        timeFrom: new Date(startOfDay(time)).valueOf(),
        timeTo: new Date(endOfDay(time)).valueOf()
    }, { refetchOnMountOrArgChange: true })

    useSetDatepicker('10min');

    useEffect(() => {
        if (power10MinAggregated && powerSum) {
            //10min aggregates
            const structured = createPowerChartDatasetStructure(power10MinAggregated as Power[])
            const limitedPower = powerSum as PowerLoss
            //powersum
            const sum1 = limitedPower.power_sum_m;
            const sum2 = limitedPower.power_sum_n;;
            const factor = sum1 / sum2;
            let totalPowerLost = 0;
            const powersumDatasets = structured.unlimitedInverters.map(dataset => {
                return {
                    x: dataset.x,
                    y: setPrecision(dataset.y * factor, 2)
                }
            })
            const limitedPowerDatasets = powersumDatasets.map((powersum, index: number) => {
                const power: number = powersum.y - structured.totalInverterEPM2Limited[index].y
                totalPowerLost += power;
                return {
                    x: powersum.x,
                    y: setPrecision(power >= 5000 ? power : 0, 2)
                }
            })
            setChartLimitedPowerDatasets(totalPowerLost === 0 ? [] : limitedPowerDatasets)
        }

    }, [time, powerSum, power10MinAggregated])

    return (
        <Fragment>
            <LineChart
                chartTitle='Moc limitowana'
                data={{
                    datasets: [
                        {
                            label: 'Moc limitowana',
                            backgroundColor: colors.lightGreen,
                            borderColor: colors.lightGreen,
                            fill: false,
                            lineTension: 0.3,
                            data: chartLimitedPowerDatasets,
                            unit: 'W'
                        },
                    ],
                }}
                yAxisTitle='Moc'
            />
        </Fragment>
    )
}