import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface AlertDialogState {
    open: boolean
    title: string
    text: string[]
}

const initialState: AlertDialogState = {
    open: false,
    title: '',
    text: []
}

export const alertDialogSlice = createSlice({
    name: 'alertDialog',
    initialState,
    reducers: {
        setDialogOpen: (state) => {
            state.open = true
        },
        setDialogClose: (state) => {
            state.open = false
        },
        setDialogData: (state, action: PayloadAction<Omit<AlertDialogState, 'open'>>) => {
            state.title = action.payload.title
            state.text = action.payload.text
        }
    },
})

export const { setDialogOpen, setDialogClose, setDialogData } = alertDialogSlice.actions

export default alertDialogSlice.reducer