import { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { mapHotelsPowerToChart } from '../../../utilities/CreateChartDataStructure.utility';
import { LineChart } from '../../LineChart.component';
import { colors } from '../../../utilities/Colors.utility';
import { useInterval } from '../../../hooks/useInterval.hook';
import { useSetDatepicker } from '../../../hooks/useSetDatepicker.hook';
import { RootState } from '../../../store';
import { useGetHotelPowerQuery } from '../../../services/power';

export const HotelCurrent = () => {
    const [chartHotel1GridPower, setChartHotel1GridPower] = useState<Array<{ x: number, y: number }>>([]);
    const [chartHotel1PvPower, setChartHotel1PvPower] = useState<Array<{ x: number, y: number }>>([]);
    const [chartHotel1Power, setChartHotel1Power] = useState<Array<{ x: number, y: number }>>([]);
    const [chartHotel2GridPower, setChartHotel2GridPower] = useState<Array<{ x: number, y: number }>>([]);
    const [chartHotel2PvPower, setChartHotel2PvPower] = useState<Array<{ x: number, y: number }>>([]);
    const [chartHotel2Power, setChartHotel2Power] = useState<Array<{ x: number, y: number }>>([]);

    const { time } = useSelector((state: RootState) => state.toolbar);
    const { data } = useGetHotelPowerQuery({ type: 'current', timeFrom: time.valueOf() - 300000, timeTo: time.valueOf() },
        { refetchOnMountOrArgChange: true })

    useInterval(4000);
    useSetDatepicker('current');

    useEffect(() => {
        if (data) {
            const mapped = mapHotelsPowerToChart(data);
            setChartHotel1GridPower(mapped.hotel1GridPower)
            setChartHotel1PvPower(mapped.hotel1PvPower)
            setChartHotel1Power(mapped.hotel1Power)
            setChartHotel2GridPower(mapped.hotel2GridPower)
            setChartHotel2PvPower(mapped.hotel2PvPower)
            setChartHotel2Power(mapped.hotel2Power)
        }
    }, [setChartHotel1GridPower, setChartHotel1PvPower, setChartHotel1Power, data])

    return (
        <Fragment>
            <LineChart
                chartTitle='Moce chwilowe'
                data={{
                    datasets: [
                        {
                            label: 'Moc sieciowa - 1',
                            backgroundColor: colors.petrol,
                            borderColor: colors.petrol,
                            fill: false,
                            lineTension: 0.3,
                            data: chartHotel1GridPower,
                            unit: 'W'
                        },
                        {
                            label: 'Moc falowników - 1',
                            backgroundColor: colors.lightOrange,
                            borderColor: colors.lightOrange,
                            fill: false,
                            lineTension: 0.3,
                            data: chartHotel1PvPower,
                            unit: 'W'
                        },
                        {
                            label: 'Moc hotelu - 1',
                            backgroundColor: colors.lighViolet,
                            borderColor: colors.lighViolet,
                            fill: false,
                            lineTension: 0.3,
                            data: chartHotel1Power,
                            unit: 'W'
                        },
                        {
                            label: 'Moc sieciowa - 2',
                            backgroundColor: colors.darkBlue,
                            borderColor: colors.darkBlue,
                            fill: false,
                            lineTension: 0.3,
                            data: chartHotel2GridPower,
                            borderDash: [3, 1],
                            unit: 'W'
                        },
                        {
                            label: 'Moc falowników - 2',
                            backgroundColor: colors.darkOrange,
                            borderColor: colors.darkOrange,
                            fill: false,
                            lineTension: 0.3,
                            data: chartHotel2PvPower,
                            borderDash: [3, 1],
                            unit: 'W'
                        },
                        {
                            label: 'Moc hotelu - 2',
                            backgroundColor: colors.darkViolet,
                            borderColor: colors.darkViolet,
                            fill: false,
                            lineTension: 0.3,
                            data: chartHotel2Power,
                            borderDash: [3, 1],
                            unit: 'W'
                        }
                    ],
                }}
                yAxisTitle='Moc'
            />
        </Fragment>
    )
}