import { add, endOfMonth, format, startOfMonth, sub } from 'date-fns';
import { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { createEnergyPlantDatasetStructure } from '../../../utilities/CreateChartDataStructure.utility';
import { BarChart } from '../../BarChart.component';
import { IInverterEnergy } from '../types';
import { PlantsEnergy, useGetEnergyQuery } from '../../../services/energy';
import { RootState } from '../../../store';

const initialState = {
    labels: [],
    datasets: []
}

export const SummarizedEnergy = () => {
    const selectedDate = useSelector((state: RootState) => state.toolbar.time);
    const [daysOfMonthChartData, setDaysOfMonthChartData] = useState<IInverterEnergy>(initialState);

    const { data } = useGetEnergyQuery({
        type: 'plants',
        timeFrom: format(sub(startOfMonth(selectedDate), { days: 1 }), 'yyyy-MM-dd'),
        timeTo: format(add(endOfMonth(selectedDate), {
            days: 1
        }), 'yyyy-MM-dd')
    }, { refetchOnMountOrArgChange: true })

    useEffect(() => {
        if (data) {
            const structured = createEnergyPlantDatasetStructure(data as PlantsEnergy[], selectedDate);
            setDaysOfMonthChartData({
                labels: structured.labels,
                datasets: structured.datasets
            })
        }
    }, [data, selectedDate])

    return (
        <Fragment>
            <BarChart
                stacked
                data={{
                    labels: daysOfMonthChartData.labels,
                    datasets: daysOfMonthChartData.datasets
                }}
                chartTitle="Energia w poszczególnych dniach"
                yAxisUnit="kWh"
            />
        </Fragment>
    )
}