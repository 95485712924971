// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export interface IPredictions {
    timestamp: string
    params: {
        [paramName: string]: {
            prediction: number
            error: number
        }
    }
}

export const tensorflowApi = createApi({
    reducerPath: 'tensorflowApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/tensorflow/' }),
    endpoints: (builder) => ({
        getPredictions: builder.query<IPredictions[], { id: string, timeFrom: number, timeTo: number }>({
            query: (data) => `${data.id}/${data.timeFrom}/${data.timeTo}`
        })
    }),
})

export const { useGetPredictionsQuery } = tensorflowApi