import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useState, Fragment } from "react";
import { PowerType } from "../../../services/power";
import { Plant1MinuteAvg } from "./Plant1MinuteAvg.component";
import { PlantCurrent } from "./PlantCurrent.component";
import { Plant10MinuteAvg } from "./Plant10MinuteAvg.component";
import { PlantLimitedPower } from "./PlantLimitedPower.component";

export const PlantPower = () => {
    const [tabValue, setTabValue] = useState<PowerType>('current');

    const getComponent = (viewType: PowerType): React.ReactNode => {
        switch (viewType) {
            case 'current': {
                return <PlantCurrent />
            }
            case '1minAggregated': {
                return <Plant1MinuteAvg />
            }
            case '10minAggregated': {
                return <Plant10MinuteAvg />
            }
            case 'limited': {
                return <PlantLimitedPower />
            }
            default: {
                return null;
            }
        }
    }

    return (
        <Fragment>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} variant="scrollable" scrollButtons="auto">
                    <Tab label="Aktualne dane" onClick={() => setTabValue('current')} value='current' />
                    <Tab label="Średnie 1-min" onClick={() => setTabValue('1minAggregated')} value='1minAggregated' />
                    <Tab label="Średnie 10-min" onClick={() => setTabValue('10minAggregated')} value='10minAggregated' />
                    <Tab label="Moc limitowana" onClick={() => setTabValue('limited')} value='limited' />
                </Tabs>
            </Box>
            {getComponent(tabValue)}
        </Fragment>
    )
}