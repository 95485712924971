import { Route, Routes } from 'react-router';
import { PowerPage } from '../components/Power/Power.component';
import { EnergyPage } from '../components/Energy/Energy.component';
import { MainPage } from '../components/MainPage.component';
import { GeneratorPage } from '../components/Generator.component';
import { PlantPower } from '../components/Power/Plant/PlantPower.component';
import { HotelPower } from '../components/Power/Hotel/HotelPower.component';
import { SettingsPage } from '../components/SettingsPage.component';
import { LogsPage } from '../components/Logs.component';
import { HotelEnergy } from '../components/Energy/Hotel/HotelEnergy';
import { PlantEnergy } from '../components/Energy/Plants/PlantEnergy.component';
import { PowerPlantEnergy } from '../components/Energy/PowerPlant/PowerPlantEnergy.component';
import { Predictions } from '../components/Predicitons.component';
import { CustomCharts } from '../components/CustomCharts.component';

export default function DrawerRoutes() {
  return (
    <Routes>
      <Route path="/moc/" element={<PowerPage />}>
        <Route path="zaklady" element={<PlantPower />}></Route>
        <Route path="hotele" element={<HotelPower />}></Route>
      </Route>

      <Route path="/energia/" element={<EnergyPage />}>
        <Route path="fotowoltaika/" element={<PowerPlantEnergy />}></Route>
        <Route path="zaklady/" element={<PlantEnergy />}></Route>
        <Route path="hotele" element={<HotelEnergy />}> </Route>
      </Route>
      <Route path="/ai" element={<Predictions />}></Route>
      <Route path="/wykresy" element={<CustomCharts />}></Route>
      <Route path="/generator" element={<GeneratorPage />}></Route>
      <Route path="/" element={<MainPage />}></Route>
      <Route path="/ustawienia/" element={<SettingsPage />}></Route>
      <Route path="/logi/" element={<LogsPage />}></Route>
      <Route path="*" element={<MainPage />}></Route>
    </Routes>
  )
}