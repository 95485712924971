import { Grid, Alert, AlertTitle, Box } from '@mui/material';
import { add, endOfMonth, format, startOfMonth } from 'date-fns';
import { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { createEnergyChartDatasetStructure } from '../../../utilities/CreateChartDataStructure.utility';
import { BarChart } from '../../BarChart.component';
import { IInverterEnergy } from '../types';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import { pl } from 'date-fns/locale';
import { RootState } from '../../../store';
import { PVEnergy, useGetEnergyQuery } from '../../../services/energy';

const initialState = {
    labels: [],
    datasets: []
}

export const SummarizedEnergy = () => {
    const selectedDate = useSelector((state: RootState) => state.toolbar.time);
    const [daysOfMonthChartData, setDaysOfMonthChartData] = useState<IInverterEnergy>(initialState);
    const [lostEnergy, setLostEnergy] = useState<number | string>(0);

    const { data, isFetching } = useGetEnergyQuery({
        type: 'pv',
        timeFrom: format(startOfMonth(selectedDate), 'yyyy-MM-dd'),
        timeTo: format(add(endOfMonth(selectedDate), {
            days: 1
        }), 'yyyy-MM-dd')
    }, { refetchOnMountOrArgChange: true })

    useEffect(() => {
        if (data) {
            const structured = createEnergyChartDatasetStructure(data as PVEnergy[], selectedDate);

            setDaysOfMonthChartData({
                labels: structured.labels,
                datasets: structured.summarizedEnergy
            })

            setLostEnergy(structured.lostEnergy);
        }
    }, [data]);

    return (
        <Fragment>
            <BarChart
                stacked
                data={{
                    labels: daysOfMonthChartData.labels,
                    datasets: daysOfMonthChartData.datasets
                }}
                chartTitle="Energia w poszczególnych dniach"
                yAxisUnit="kWh"
            />
            <Box sx={{ p: 2 }}>
                <Grid container justifyContent={'flex-end'}>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Alert severity="warning" variant="outlined" icon={<EnergySavingsLeafIcon fontSize="inherit" />}>
                            <AlertTitle>Utracona energia - {format(selectedDate, 'LLLL yyyy', { locale: pl })}</AlertTitle>
                            {isFetching ? '-' : lostEnergy} kWh
                        </Alert>
                    </Grid>
                </Grid>
            </Box>
        </Fragment>
    )
}