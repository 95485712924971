export const colors = {
    petrol: '#5bcdb6',
    lightOrange: '#ffbd44',
    darkBlue: '#00737d',
    darkOrange: '#BA4A00',
    lightPink: '#f0aac3',
    pink: '#BE2180',
    lightGreen: '#9ae100',
    darkGreen: '#008500',
    lighViolet: '#b499fd',
    darkViolet: '#8a03ff'
  }