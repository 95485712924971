import React, { createContext, useEffect, useMemo, useState } from 'react';
import './App.css';
import {
  ThemeProvider
} from '@mui/material/styles';
import { createTheme } from '@mui/material/styles'
import { pl } from 'date-fns/locale';
import { BrowserRouter as Router } from 'react-router-dom';
import { MainDrawer } from './components/Drawer.component';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AlertDialog } from './components/AlertDialog.component';

export const ColorModeContext = createContext({ toggleColorMode: () => { } });

function App() {
  const [mode, setMode] = useState<'light' | 'dark'>(localStorage.getItem('theme') as 'light' | 'dark' || 'light');
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === 'light' ? 'dark' : 'light';
          localStorage.setItem('theme', newMode);

          return newMode
        })
      },
    }),
    [],
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === 'light'
            ? {
              // palette values for light mode
              primary: {
                light: '#50bed7',
                main: '#3296b9',
                dark: '#00557d',
                contrastText: '#fff',
              }
            }
            : {
              // palette values for dark mode
              action: {
                active: '#fff',
                hover: 'rgba(255, 255, 255, 0.08)',
                selected: 'rgba(255, 255, 255, 0.16)',
                disabled: 'rgba(255, 255, 255, 0.3)',
                disabledBackground: 'rgba(255, 255, 255, 0.12)'
              },
              divider: 'rgba(255, 255, 255, 0.12)',
              background: {
                default: '#121212',
                paper: '#121212',
              },
              text: {
                primary: '#fff',
                secondary: 'rgba(255, 255, 255, 0.7)',
                disabled: 'rgba(255, 255, 255, 0.5)'
              },
            }),

        },
        components: {
          MuiAlert: {
            styleOverrides: {
              message: {
                width: '100%'
              }
            }
          }
        }
      }),
    [mode],
  );

  useEffect(() => {
    const metaThemeColor = document.querySelector('meta[name=theme-color]');
    metaThemeColor?.setAttribute('content', mode === 'dark' ? '#121212' : '#3296b9');
  }, [mode])

  return (
    <React.Fragment>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl}>
              <Router>
                <AlertDialog />
                <MainDrawer />
              </Router>
          </LocalizationProvider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </React.Fragment>
  );
}

export default App;
