import { useState, Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createPowerChartDatasetStructure } from '../../../utilities/CreateChartDataStructure.utility';
import { LineChart } from '../../LineChart.component';
import { IInverterDatasets } from '../types';
import { colors } from '../../../utilities/Colors.utility';
import { useInterval } from '../../../hooks/useInterval.hook';
import { useSetDatepicker } from '../../../hooks/useSetDatepicker.hook';
import { RootState } from '../../../store';
import { Power, useGetPowerQuery } from '../../../services/power';

export const PlantCurrent = () => {
    const [chartDatasets, setChartDatasets] = useState<IInverterDatasets>({
        totalInvertersPowerPlant1Dataset: [],
        totalInvertersPowerPlant2Dataset: [],
        consumedPower2Dataset: [],
        consumedPower1Dataset: [],
        gridAndGeneratorPower2Dataset: [],
        gridAndGeneratorPower1Dataset: [],
    });
    const { time } = useSelector((state: RootState) => state.toolbar);
    const { data } = useGetPowerQuery({
        type: 'current',
        timeFrom: time.valueOf() - 300000,
        timeTo: time.valueOf()
    },
        { refetchOnMountOrArgChange: true })

    useInterval(4000);
    useSetDatepicker('current');

    useEffect(() => {
        if (data) {
            const structured = createPowerChartDatasetStructure(data as Power[])
            setChartDatasets({
                totalInvertersPowerPlant1Dataset: structured.totalInvertersPowerPlant1,
                totalInvertersPowerPlant2Dataset: structured.totalInvertersPowerPlant2,
                consumedPower2Dataset: structured.consumedPower2,
                consumedPower1Dataset: structured.consumedPower1,
                gridAndGeneratorPower2Dataset: structured.inst_n_grid_generator_power,
                gridAndGeneratorPower1Dataset: structured.inst_m_grid_generator_power
            })
        }
    }, [data])

    return (
        <Fragment>
            <LineChart
                chartTitle='Moce chwilowe'
                data={{
                    datasets: [
                        {
                            label: 'Moc sieciowa - zakład-M',
                            backgroundColor: colors.petrol,
                            borderColor: colors.petrol,
                            fill: false,
                            lineTension: 0.3,
                            data: chartDatasets.gridAndGeneratorPower1Dataset,
                            unit: 'W'
                        },
                        {
                            label: 'Moc falowników - zakład-M',
                            backgroundColor: colors.lightOrange,
                            borderColor: colors.lightOrange,
                            fill: false,
                            lineTension: 0.3,
                            data: chartDatasets.totalInvertersPowerPlant1Dataset,
                            unit: 'W'
                        },
                        {
                            label: 'Moc zakład-M',
                            backgroundColor: colors.lighViolet,
                            borderColor: colors.lighViolet,
                            fill: false,
                            lineTension: 0.3,
                            data: chartDatasets.consumedPower1Dataset,
                            unit: 'W'
                        },
                        {
                            label: 'Moc sieciowa - zakład-N',
                            backgroundColor: colors.darkBlue,
                            borderColor: colors.darkBlue,
                            fill: false,
                            lineTension: 0.3,
                            data: chartDatasets.gridAndGeneratorPower2Dataset,
                            borderDash: [3, 1],
                            unit: 'W'
                        },
                        {
                            label: 'Moc falowników - zakład-N',
                            backgroundColor: colors.darkOrange,
                            borderColor: colors.darkOrange,
                            fill: false,
                            lineTension: 0.3,
                            data: chartDatasets.totalInvertersPowerPlant2Dataset,
                            borderDash: [3, 1],
                            unit: 'W'
                        },
                        {
                            label: 'Moc zakład-N',
                            backgroundColor: colors.darkViolet,
                            borderColor: colors.darkViolet,
                            fill: false,
                            lineTension: 0.3,
                            data: chartDatasets.consumedPower2Dataset,
                            borderDash: [3, 1],
                            unit: 'W'
                        }
                    ],
                }}
                yAxisTitle='Moc'
            />
        </Fragment>
    )
}