import { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { createPowerChartDatasetStructure } from '../../../utilities/CreateChartDataStructure.utility';
import { LineChart } from '../../LineChart.component';
import { IInverterDatasets } from '../types';
import { colors } from '../../../utilities/Colors.utility';
import { endOfDay, startOfDay } from 'date-fns';
import { useSetDatepicker } from '../../../hooks/useSetDatepicker.hook';
import { RootState } from '../../../store';
import { Power, useGetPowerQuery } from '../../../services/power';

export const Plant10MinuteAvg = () => {
    const [chartDatasets10MinAggregated, setChartDatasets10MinAggregated] = useState<IInverterDatasets>({
        totalInvertersPowerPlant1Dataset: [],
        totalInvertersPowerPlant2Dataset: [],
        consumedPower2Dataset: [],
        consumedPower1Dataset: [],
        gridAndGeneratorPower2Dataset: [],
        gridAndGeneratorPower1Dataset: [],
    });

    const { time } = useSelector((state: RootState) => state.toolbar);
    const { data } = useGetPowerQuery({
        type: '10minAggregated',
        timeFrom: new Date(startOfDay(time)).valueOf(),
        timeTo: new Date(endOfDay(time)).valueOf()
    },
        { refetchOnMountOrArgChange: true })

    useSetDatepicker('10min');

    useEffect(() => {
        if (data) {
            const structured = createPowerChartDatasetStructure(data as Power[])

            setChartDatasets10MinAggregated({
                totalInvertersPowerPlant1Dataset: structured.totalInvertersPowerPlant1,
                totalInvertersPowerPlant2Dataset: structured.totalInvertersPowerPlant2,
                consumedPower2Dataset: structured.consumedPower2,
                consumedPower1Dataset: structured.consumedPower1,
                gridAndGeneratorPower2Dataset: structured.inst_n_grid_generator_power,
                gridAndGeneratorPower1Dataset: structured.inst_m_grid_generator_power
            })
        }
    }, [data])

    return (
        <Fragment>
            <LineChart
                chartTitle='Moce chwilowe'
                data={{
                    datasets: [
                        {
                            label: 'Moc sieciowa - zakład-M',
                            backgroundColor: colors.petrol,
                            borderColor: colors.petrol,
                            fill: false,
                            lineTension: 0.3,
                            data: chartDatasets10MinAggregated.gridAndGeneratorPower1Dataset,
                            unit: 'W'
                        },
                        {
                            label: 'Moc falowników - zakład-M',
                            backgroundColor: colors.lightOrange,
                            borderColor: colors.lightOrange,
                            fill: false,
                            lineTension: 0.3,
                            data: chartDatasets10MinAggregated.totalInvertersPowerPlant1Dataset,
                            unit: 'W'
                        },
                        {
                            label: 'Moc zakład-M',
                            backgroundColor: colors.lighViolet,
                            borderColor: colors.lighViolet,
                            fill: false,
                            lineTension: 0.3,
                            data: chartDatasets10MinAggregated.consumedPower1Dataset,
                            unit: 'W'
                        },
                        {
                            label: 'Moc sieciowa - zakład-N',
                            backgroundColor: colors.darkBlue,
                            borderColor: colors.darkBlue,
                            fill: false,
                            lineTension: 0.3,
                            data: chartDatasets10MinAggregated.gridAndGeneratorPower2Dataset,
                            borderDash: [3, 1],
                            unit: 'W'
                        },
                        {
                            label: 'Moc falowników - zakład-N',
                            backgroundColor: colors.darkOrange,
                            borderColor: colors.darkOrange,
                            fill: false,
                            lineTension: 0.3,
                            data: chartDatasets10MinAggregated.totalInvertersPowerPlant2Dataset,
                            borderDash: [3, 1],
                            unit: 'W'
                        },
                        {
                            label: 'Moc zakład-N',
                            backgroundColor: colors.darkViolet,
                            borderColor: colors.darkViolet,
                            fill: false,
                            lineTension: 0.3,
                            data: chartDatasets10MinAggregated.consumedPower2Dataset,
                            borderDash: [3, 1],
                            unit: 'W'
                        }
                    ],
                }}
                yAxisTitle='Moc'
            />
        </Fragment>
    )
}